/**
 * @generated SignedSource<<f97b488c1a8a6b1b2511515a7a06e06d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type IssueFocusScreenSrolledSubscription$variables = {
  widgetId: string;
};
export type IssueFocusScreenSrolledSubscription$data = {
  readonly widgetFocusScrollChanged: string;
};
export type IssueFocusScreenSrolledSubscription = {
  response: IssueFocusScreenSrolledSubscription$data;
  variables: IssueFocusScreenSrolledSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "widgetId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "widgetId",
        "variableName": "widgetId"
      }
    ],
    "kind": "ScalarField",
    "name": "widgetFocusScrollChanged",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IssueFocusScreenSrolledSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IssueFocusScreenSrolledSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2516d365d53c49a2a8d000a2de7e2948",
    "id": null,
    "metadata": {},
    "name": "IssueFocusScreenSrolledSubscription",
    "operationKind": "subscription",
    "text": "subscription IssueFocusScreenSrolledSubscription(\n  $widgetId: ID!\n) {\n  widgetFocusScrollChanged(widgetId: $widgetId)\n}\n"
  }
};
})();

(node as any).hash = "7b2872caaed9d25c43e7607e39ed46f1";

export default node;
