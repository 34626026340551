import { useEffect, useRef, useState } from "react";

import { DateTime } from "luxon";

const getTime = () => {
  return DateTime.local();
};

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
    return () => {
      savedCallback.current = () => {};
    };
  }, [callback]);

  useEffect(() => {
    const handler = () => savedCallback.current!();

    if (delay !== null) {
      const id = setInterval(handler, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const useTime = (refreshCycle = 6000) => {
  const [now, setNow] = useState(getTime());
  useInterval(() => setNow(getTime()), refreshCycle);
  return now;
};
