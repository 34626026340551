import { Box, useScaleFactor, useTime } from "arkh";

import React, { useMemo, useRef, ForwardedRef } from "react";

import { useFragment, graphql } from "react-relay";

import { useSubscriptionWithSkip } from "widgets/src/utils/useSubscriptionWithSkip";
import {
  TeamScheduleFocusScreen_widgetTeamSchedule$key,
  WidgetOverviewSize,
} from "./__generated__/TeamScheduleFocusScreen_widgetTeamSchedule.graphql";
import { TeamScheduleFocusScreenSummary_contentSummary$key } from "./__generated__/TeamScheduleFocusScreenSummary_contentSummary.graphql";
import { WidgetTeamScheduleContentSectionHeader } from "widgets/src/WidgetTeamSchedule/WidgetTeamScheduleContentSectionHeader";
import {
  WidgetTeamScheduleContentList,
  WidgetTeamScheduleContentListRef,
} from "widgets/src/WidgetTeamSchedule/WidgetTeamScheduleContentList";
import { TeamScheduleFocusScreenColumn_teamSchedule$key } from "./__generated__/TeamScheduleFocusScreenColumn_teamSchedule.graphql";
import { DateTime } from "luxon";
import Color from "color";
import { useNowIndexes } from "widgets/src/WidgetTeamSchedule/useNowIndexes";
import { TeamScheduleFocusScreenSrolledSubscription } from "./__generated__/TeamScheduleFocusScreenSrolledSubscription.graphql";

function noOp() {}
export const TeamScheduleFocusScreen = (props: {
  widget: TeamScheduleFocusScreen_widgetTeamSchedule$key;
}) => {
  const widget = useFragment(
    graphql`
      fragment TeamScheduleFocusScreen_widgetTeamSchedule on WidgetTeamSchedule {
        id
        teams {
          id
          color
        }
        overviewSize

        content {
          __typename
          ... on WidgetTeamScheduleContentDaily {
            column: dailyTeamSchedule {
              events(includeInlineRoutines: true) {
                ...useNowIndexes_teamScheduleEventOccasion
              }
              ...TeamScheduleFocusScreenColumn_teamSchedule
            }
          }
          ... on WidgetTeamScheduleContentWeekly {
            column: weeklyTeamSchedule {
              ...TeamScheduleFocusScreenColumn_teamSchedule
            }
          }
          ... on WidgetTeamScheduleContentMonthly {
            column: monthlyTeamSchedule {
              ...TeamScheduleFocusScreenColumn_teamSchedule
            }
          }
          ... on WidgetTeamScheduleContentSummary {
            dailyTeamSchedule {
              events(includeInlineRoutines: true) {
                ...useNowIndexes_teamScheduleEventOccasion
              }
            }
            monthlyTeamSchedule {
              events(includeInlineRoutines: true) {
                id
              }
            }
            weeklyTeamSchedule {
              events(includeInlineRoutines: true) {
                id
              }
            }
            ...TeamScheduleFocusScreenSummary_contentSummary
          }
        }
      }
    `,
    props.widget
  );

  const sf = useScaleFactor();

  const team = widget.teams[0];
  const now = useTime();
  const [nowIndexes, scrollIndex] = useNowIndexes({
    eventKey:
      widget.content.__typename === "WidgetTeamScheduleContentDaily"
        ? widget.content.column.events
        : widget.content.__typename === "WidgetTeamScheduleContentSummary"
        ? widget.content.dailyTeamSchedule.events
        : [],
    now,
  });

  const listRef = useRef<WidgetTeamScheduleContentListRef>(null);
  useSubscriptionWithSkip<TeamScheduleFocusScreenSrolledSubscription>(
    useMemo(
      () => ({
        subscription: graphql`
          subscription TeamScheduleFocusScreenSrolledSubscription(
            $widgetId: ID!
          ) {
            widgetFocusScrollChanged(widgetId: $widgetId)
          }
        `,
        variables: { widgetId: widget.id },
        onNext: (data) => {
          listRef.current?.scrollToItem(data?.widgetFocusScrollChanged ?? null);
        },
      }),
      [widget.id]
    )
  );

  if (!team) {
    return null;
  }

  const width = () => {
    if (widget.content.__typename === "WidgetTeamScheduleContentSummary") {
      let width = 50;
      if (widget.content.monthlyTeamSchedule.events.length > 0) {
        width += 20;
      }
      if (widget.content.weeklyTeamSchedule.events.length > 0) {
        width += 20;
      }
      return width;
    }
    return 50;
  };

  return (
    <Box style={{ flex: 1, width: `${width()}%`, alignSelf: "center" }}>
      <Box
        style={{
          flex: 1,
          flexDirection: "row",
          overflow: "hidden",
        }}
        cornerRadius="medium"
        color="surface"
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {widget.content.__typename === "WidgetTeamScheduleContentSummary" ? (
            <Summary
              contentSummary={widget.content}
              tint={team.color}
              teamId={team.id}
              size={widget.overviewSize}
              dailyNowIndexes={nowIndexes}
              ref={listRef}
            />
          ) : null}
          {widget.content.__typename === "WidgetTeamScheduleContentDaily" ||
          widget.content.__typename === "WidgetTeamScheduleContentWeekly" ||
          widget.content.__typename === "WidgetTeamScheduleContentMonthly" ? (
            <Column
              tint={team.color}
              teamId={team.id}
              size={widget.overviewSize}
              teamSchedule={widget.content.column}
              now={now}
              ref={listRef}
              nowIndexes={
                widget.content.__typename === "WidgetTeamScheduleContentDaily"
                  ? nowIndexes
                  : undefined
              }
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

const Summary = React.forwardRef(_Summary);
function _Summary(
  props: {
    contentSummary: TeamScheduleFocusScreenSummary_contentSummary$key;
    tint: string;
    size: WidgetOverviewSize;
    teamId: string;
    dailyNowIndexes: number[];
  },
  ref: ForwardedRef<WidgetTeamScheduleContentListRef>
) {
  const schedule = useFragment(
    graphql`
      fragment TeamScheduleFocusScreenSummary_contentSummary on WidgetTeamScheduleContentSummary {
        dailyTeamSchedule {
          ...TeamScheduleFocusScreenColumn_teamSchedule
        }
        weeklyTeamSchedule {
          events(includeInlineRoutines: true) {
            id
          }
          ...TeamScheduleFocusScreenColumn_teamSchedule
        }
        monthlyTeamSchedule {
          events(includeInlineRoutines: true) {
            id
          }
          ...TeamScheduleFocusScreenColumn_teamSchedule
        }
      }
    `,
    props.contentSummary
  );
  const now = useTime();

  return (
    <Box style={{ flex: 1, flexDirection: "row" }}>
      {schedule.monthlyTeamSchedule.events.length > 0 ? (
        <Column
          teamId={props.teamId}
          tint={props.tint}
          size={props.size}
          teamSchedule={schedule.monthlyTeamSchedule}
          now={now}
        />
      ) : null}
      {schedule.weeklyTeamSchedule.events.length > 0 ? (
        <Column
          teamId={props.teamId}
          tint={props.tint}
          size={props.size}
          teamSchedule={schedule.weeklyTeamSchedule}
          now={now}
        />
      ) : null}
      <Column
        ref={ref}
        teamId={props.teamId}
        tint={props.tint}
        size={props.size}
        teamSchedule={schedule.dailyTeamSchedule}
        now={now}
        nowIndexes={props.dailyNowIndexes}
      />
    </Box>
  );
}

const Column = React.forwardRef(_Column);
function _Column(
  props: {
    teamSchedule: TeamScheduleFocusScreenColumn_teamSchedule$key;
    tint: string;
    size: WidgetOverviewSize;
    teamId: string;
    now: DateTime;
    nowIndexes?: number[];
  },
  ref: ForwardedRef<WidgetTeamScheduleContentListRef>
) {
  const teamSchedule = useFragment(
    graphql`
      fragment TeamScheduleFocusScreenColumn_teamSchedule on TeamSchedule {
        ...WidgetTeamScheduleContentList_teamSchedule
        ...WidgetTeamScheduleContentSectionHeader_teamSchedule
        ...WidgetTeamScheduleContentStatus_teamSchedule
      }
    `,
    props.teamSchedule
  );
  const isDarkTint = Color(props.tint).darken(0.35).isDark();

  const color = Color(props.tint).lightness(98).toString();

  return (
    <Box space="medium" style={{ flex: 1 }}>
      <Box
        cornerRadius="medium"
        style={{ flex: 1, backgroundColor: color, overflow: "hidden" }}
      >
        <WidgetTeamScheduleContentSectionHeader
          teamSchedule={teamSchedule}
          tint={props.tint}
        />
        <WidgetTeamScheduleContentList
          teamId={props.teamId}
          isDarkTint={isDarkTint}
          tint={props.tint}
          size={props.size}
          teamSchedule={teamSchedule}
          now={props.now}
          onPressRoutineOccasion={noOp}
          nowIndexes={props.nowIndexes}
          mode="IDLE"
          idiom="STATIC"
          ref={ref}
        />
      </Box>
    </Box>
  );
}
