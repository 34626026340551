import React from "react";
import { AHText, Box } from "arkh";
import { useTime } from "../../useTime";

export function Clock() {
  const time = useTime(6000);
  return (
    <Box spaceRight="large">
      <Box color="surface" space="small" cornerRadius="medium">
        <AHText type="subtitle">{time.toFormat("HH:mm")}</AHText>
      </Box>
    </Box>
  );
}
