import {
  DeviceCodeSuccessResult,
  getDeviceCode,
} from "./services/getDeviceCode";
import { assign, createMachine } from "xstate";

import { checkForToken } from "./services/checkForToken";
import { clearLocalState } from "./services/clearLocalState";
import { persistLocalState } from "./services/persistLocalState";
import { refreshToken } from "./services/refreshToken";
import { retriveLocalStorage } from "./services/retriveLocalStorage";

interface LoggedIn {
  value: "loggedIn";
  context: {
    authToken: string;
    refreshToken?: string;
    idToken: string;
    currentStoreId?: string;
    deviceId: string;
  };
}

interface PollingForToken {
  value: "pollingForToken";
  context: DeviceCodeSuccessResult & { pollingTokenCount?: number };
}
interface WaitingPollingForToken {
  value: "waitingForPollingToken";
  context: DeviceCodeSuccessResult & { pollingTokenCount?: number };
}
interface RequestingCodeErrorRetrying {
  value: "requestingCodeError";
  context: {
    error: any;
  };
}
interface RequestingCode {
  value: "requestingCode";
  context: {
    lastError?: any;
  };
}

type AuthContext = Partial<
  LoggedIn["context"] &
    PollingForToken["context"] &
    RequestingCode["context"] &
    WaitingPollingForToken["context"] &
    RequestingCodeErrorRetrying["context"]
>;

type AuthEvent =
  | { type: "REFRESH_TOKEN" }
  | { type: "STORECAST_SCREEN_DEVICE_UNDEFINED" };

export const authState = createMachine(
  {
    id: "auth",
    tsTypes: {} as import("./machine.typegen").Typegen0,
    initial: "retriveLocalStorage",
    context: {},
    schema: {
      context: {} as AuthContext,
      events: {} as AuthEvent,
      services: {} as {
        retriveLocalStorage: {
          data: Awaited<ReturnType<typeof retriveLocalStorage>>;
        };
        getDeviceCode: { data: Awaited<ReturnType<typeof getDeviceCode>> };
        checkForToken: { data: Awaited<ReturnType<typeof checkForToken>> };
        refreshToken: { data: Awaited<ReturnType<typeof refreshToken>> };
      },
    },
    states: {
      retriveLocalStorage: {
        invoke: {
          src: "retriveLocalStorage",
          onDone: {
            target: "loggedIn",
            actions: "retrivedLocalStorage",
          },
          onError: { target: "requestingCode" },
        },
      },
      requestingCode: {
        entry: ["clearLocalState", "resetContext"],
        invoke: {
          src: "getDeviceCode",
          onDone: {
            target: "pollingForToken",
            actions: "pollForToken",
          },
          onError: { target: "requestingCodeError" },
        },
      },
      requestingCodeError: {
        after: { NETWORK_ERROR_TIMEOUT: "requestingCode" },
      },
      waitingForPollingToken: {
        after: { POLLING_DELAY: "pollingForToken" },
      },
      pollingForToken: {
        invoke: {
          src: "checkForToken",
          onError: "requestingCode",
          onDone: [
            {
              target: "loggedIn",
              cond: "pollingTokenComplete",
              actions: "pollingDone",
            },
            {
              target: "requestingCode",
              cond: "pollingTokenNeedsRefresh",
            },
            {
              target: "waitingForPollingToken",
              cond: "pollingTokenContinue",
              actions: "continuePolling",
            },
            {
              target: "waitingForPollingToken",
              cond: "pollingTokenNetworkError",
              actions: "continuePolling",
            },
            {
              target: "requestingCode",
              cond: "pollingTokenError",
            },
          ],
        },
      },
      loggedIn: {
        initial: "ready",
        on: {
          STORECAST_SCREEN_DEVICE_UNDEFINED: "requestingCode",
        },
        states: {
          ready: {
            entry: "persistLocalState",
            on: {
              REFRESH_TOKEN: "refreshToken",
            },
          },
          refreshToken: {
            invoke: {
              src: "refreshToken",
              onDone: [
                {
                  target: "ready",
                  cond: "refreshTokenSuccess",
                  actions: "refreshedToken",
                },
                {
                  target: "refreshTokenNetworkError",
                  cond: "refreshTokenNetworkError",
                },
              ],
              onError: {
                target: "refreshTokenFailure",
              },
            },
          },
          refreshTokenNetworkError: {
            after: { NETWORK_ERROR_TIMEOUT: "refreshToken" },
          },
          refreshTokenFailure: {
            type: "final",
          },
        },
        exit: "clearLocalState",
        onDone: "requestingCode",
      },
    },
  },
  {
    services: {
      retriveLocalStorage: () => retriveLocalStorage(),
      getDeviceCode,
      checkForToken: (ctx) => checkForToken(ctx.deviceCode!),
      refreshToken: (ctx) => {
        if (!ctx.refreshToken) {
          throw new Error("no refresh token");
        }
        return refreshToken(ctx.refreshToken, ctx.deviceId, ctx.authToken);
      },
    },
    actions: {
      resetContext: () => assign(() => ({ pollingTokenCount: undefined })),
      clearLocalState: () => clearLocalState(),
      persistLocalState: ({
        authToken,
        currentStoreId,
        idToken,

        refreshToken,
      }) =>
        persistLocalState({
          authToken,
          currentStoreId,
          idToken,
          refreshToken,
        }),
      retrivedLocalStorage: assign((_ctx, e) => {
        return { ...(e.data ?? {}), pollingTokenCount: 0 };
      }),
      refreshedToken: assign((_ctx, e) => {
        if (!e.data.success) {
          throw new Error("Refresh token invariant");
        }

        return e.data;
      }),
      pollingDone: assign((_ctx, e) => e.data),
      continuePolling: assign((ctx, e) =>
        e.data.success === false && e.data.error === "slow_down"
          ? {
              interval: (ctx.interval ?? 5000) + 1000,
              pollingTokenCount: (ctx.pollingTokenCount ?? 0) + 1,
            }
          : { pollingTokenCount: (ctx.pollingTokenCount ?? 0) + 1 }
      ),
      pollForToken: assign((_ctx, e) => {
        return { ...(e.data ?? {}), pollingTokenCount: 0 };
      }),
    },
    guards: {
      refreshTokenSuccess: (_, e) => e.data.success === true,
      refreshTokenNetworkError: (_, e) => e.data.success === false,
      pollingTokenComplete: (_, e) => {
        return e.data.success === true;
      },
      pollingTokenNetworkError: (_, e) =>
        e.data.success === false &&
        (e.data.error === "network_error" || e.data.error === "slow_down"),
      pollingTokenContinue: (_, e) =>
        e.data.success === false &&
        (e.data.error === "authorization_pending" ||
          e.data.error === "slow_down"),
      pollingTokenNeedsRefresh: (ctx) => (ctx.pollingTokenCount ?? 0) > 80,
      pollingTokenError: (_, e) =>
        e.data.success === false &&
        (e.data.error === "expired_token" || e.data.error === "access_denied"),
    },
    delays: {
      NETWORK_ERROR_TIMEOUT: 4000,
      POLLING_DELAY: (context) => {
        return context.interval ?? 6000;
      },
    },
  }
);
