import { AHQueryHandlerLoading, AHText, useTheme } from "arkh";
import { StyleSheet, View } from "react-native";

import { PollForTokenScreen } from "./PollForTokenScreen";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { StorecastScreen } from "../features/Storecast/StorecastScreen";
import { authService } from "./auth/authService";
import { useActor } from "@xstate/react";
import {
  graphql,
  RelayEnvironmentProvider,
  useLazyLoadQuery,
} from "react-relay";
import { RelayEnvironment } from "../Client";

import { IntlProvider } from "react-intl";

import { MainLocaleQuery } from "./__generated__/MainLocaleQuery.graphql";
import { Settings } from "luxon";
import { getLocaleMessages } from "./getLocaleMessages";
const styles = StyleSheet.create({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    flexDirection: "column",
  },
});

export function Main(props: { guessLocale: "sv" | "en" }) {
  const [currentState] = useActor(authService);

  const theme = useTheme();

  if (
    (currentState.matches("pollingForToken") ||
      currentState.matches("waitingForPollingToken")) &&
    currentState.context.userCode
  ) {
    return <PollForTokenScreen userCode={currentState.context.userCode} />;
  }

  if (currentState.matches("requestingCode")) {
    return (
      <View style={[styles.container, { backgroundColor: theme.background }]}>
        <AHText>Loading...</AHText>
      </View>
    );
  }
  if (currentState.matches("requestingCodeError")) {
    return (
      <View style={[styles.container, { backgroundColor: theme.background }]}>
        <AHText
          type="title-small"
          style={{ textAlign: "center", marginLeft: 100, marginRight: 100 }}
        >
          Error encountered when trying to connect to the authorization server.
          Please check the internet connection.
        </AHText>
        <AHText type="body">Retrying...</AHText>
      </View>
    );
  }
  if (currentState.matches("loggedIn")) {
    return <LoggedIn guessLocale={props.guessLocale} />;
  }

  return (
    <View style={{ flex: 1 }}>
      <AHText type="body">Retrying...</AHText>
    </View>
  );
}

function LoggedIn(props: { guessLocale: "sv" | "en" }) {
  const [locale, setLocale] = useState<"sv" | "en">(props.guessLocale);

  useEffect(() => {
    Settings.defaultLocale = locale;
  }, [locale]);
  const relayEnv = useRef(RelayEnvironment(locale));

  const setNewLocale = useCallback(
    (newLocale: "sv" | "en" | "nb") => {
      const newSupportedLocale = newLocale === "sv" ? "sv" : "en";
      if (locale !== newSupportedLocale) {
        relayEnv.current.dispose();
        relayEnv.current = RelayEnvironment(newSupportedLocale);
        setLocale(newSupportedLocale);
      }
    },
    [locale]
  );

  return (
    <IntlProvider
      defaultLocale="en"
      locale={locale}
      messages={getLocaleMessages(locale)}
    >
      <RelayEnvironmentProvider environment={relayEnv.current.env}>
        <Suspense fallback={<AHQueryHandlerLoading />}>
          <StorecastScreen />
        </Suspense>
        <Suspense fallback={null}>
          <Locale setLocale={setNewLocale} />
        </Suspense>
      </RelayEnvironmentProvider>
    </IntlProvider>
  );
}

function Locale({ setLocale }: { setLocale: (l: "sv" | "en" | "nb") => void }) {
  const query = useLazyLoadQuery<MainLocaleQuery>(
    graphql`
      query MainLocaleQuery {
        store {
          locale
          timezone
        }
      }
    `,
    {}
  );
  useEffect(() => {
    Settings.defaultZone = query.store.timezone;
    setLocale(query.store.locale);
  }, [query.store.locale, query.store.timezone, setLocale]);

  return null;
}
