import { Box, ScaleFactorProvider, TouchableBox, useScaleFactor } from "arkh";
import React, {
  PropsWithChildren,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StyleSheet, View } from "react-native";
import {
  graphql,
  PreloadedQuery,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  useSubscription,
} from "react-relay";
import {
  WidgetIpoolIntegration,
  WidgetLayoutProvider,
  WidgetNote,
  WidgetRoutinePositivePercentage,
  WidgetTaskCollection,
  WidgetTaskSingle,
  WidgetTeamSchedule,
  WidgetWeather,
} from "widgets";
import { WidgetEmployeeSchedule } from "widgets/src/WidgetEmployeeSchedule/WidgetEmployeeSchedule";
import { WidgetIssueSummary } from "widgets/src/WidgetIssueSummary/WidgetIssueSummary";

import { UnitSizeProvider, useUnitSize } from "../../OS/UnitSizeProvider";
import { FileFocusScreen } from "./File/FileFocusScreen";
import { IssueFocusScreen } from "./Issue/IssueFocusScreen";
import { PushAppyFocusScreen } from "./PushAppy/PushAppyFocusScreen";
import { TodoFocusScreen } from "./Todo/TodoFocusScreen";
import { FocusSingleWidgetQuery } from "./__generated__/FocusSingleWidgetQuery.graphql";
import {
  FocusSubscription,
  FocusSubscription$data,
} from "./__generated__/FocusSubscription.graphql";
import { WidgetIntegrationTurnpike } from "widgets/src/WidgetIntegrationTurnpike/WidgetIntegrationTurnpike";
import { WidgetCalendar } from "widgets/src/WidgetCalendar/WidgetCalendar";
import {
  WidgetFile,
  WidgetFileStatic,
} from "widgets/src/WidgetFile/WidgetFile";
import { TeamScheduleFocusScreen } from "./TeamSchedule/TeamScheduleFocusScreen";
import { FocusTVAckMutation } from "./__generated__/FocusTVAckMutation.graphql";
import { replay } from "../../../replay";

const FocusModalWrapper = (props: PropsWithChildren<object>) => {
  return (
    <Suspense fallback={null}>
      <View
        style={{
          ...StyleSheet.absoluteFillObject,
          justifyContent: "center",
          alignItems: "center",
          transform: [{ rotateZ: "0deg" }],
        }}
      >
        <TouchableBox
          style={[StyleSheet.absoluteFill, { opacity: 0.7 }]}
          color="darkerText"
        />

        {props.children}
      </View>
    </Suspense>
  );
};

const WIDGET_DETAIL_SCALE_FACTOR = 1.5;
function WidgetWrapper(
  props: PropsWithChildren<{
    forceScale?: number;
    w: number;
    h: number;
  }>
) {
  const { w, h } = props;

  const scale = props.forceScale ?? WIDGET_DETAIL_SCALE_FACTOR;
  const unitSize = useUnitSize() * scale;

  return (
    <ScaleFactorProvider value={unitSize / 236}>
      <WidgetLayoutProvider
        value={{ unitSize, width: w * unitSize, height: h * unitSize, h }}
      >
        <UnitSizeProvider value={unitSize}>
          <Box
            style={{
              flex: 1,
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              shadow="medium"
              style={{
                width: w * unitSize,
                height: h * unitSize,
              }}
              cornerRadius="medium"
            >
              <Box
                style={{
                  flex: 1,
                  overflow: "hidden",
                  width: w * unitSize,
                  height: h * unitSize,
                }}
                cornerRadius="medium"
              >
                <Suspense
                  fallback={<Box color="surface" style={{ flex: 1 }} />}
                >
                  {props.children}
                </Suspense>
              </Box>
            </Box>
          </Box>
        </UnitSizeProvider>
      </WidgetLayoutProvider>
    </ScaleFactorProvider>
  );
}

const FOCUS_QUERY = graphql`
  query FocusSingleWidgetQuery($nodeId: ID!, $first: Int, $after: String) {
    node(id: $nodeId) {
      ... on WidgetFile {
        ...WidgetFileStatic_widgetFile
        __typename
      }
      ... on WidgetIssueSummary {
        ...WidgetIssueSummary_widgetIssueSummary
        __typename
      }
      ... on WidgetEmployeeSchedule {
        ...WidgetEmployeeSchedule_widgetEmployeeSchedule
        __typename
      }
      ... on WidgetIssueOverview {
        ...IssueFocusScreen_widgetIssueOverview
        __typename
      }
      ... on WidgetTodoOverview {
        ...TodoFocusScreen_widgetTodoOverview
        __typename
      }

      ... on WidgetTeamSchedule {
        ...WidgetTeamSchedule_widget
        ...TeamScheduleFocusScreen_widgetTeamSchedule
        ...useWidgetToPosSingleSize_widget
        __typename
      }
      ... on WidgetWeather {
        ...WidgetWeather_widget
        ...useWidgetToPosSingleSize_widget
        __typename
      }
      ... on WidgetTodoOverview {
        ...WidgetTodoOverview_widget
        ...useWidgetToPosSingleSize_widget
        __typename
      }
      ... on WidgetNote {
        ...WidgetNote_widgetNote
        ...useWidgetToPosSingleSize_widget
        noteSize
        __typename
      }
      ... on WidgetTaskSingle {
        ...WidgetTaskSingle_widgetTaskSingle
        ...useWidgetToPosSingleSize_widget
        __typename
      }
      ... on WidgetTaskCollection {
        ...WidgetTaskCollection_widgetTaskCollection
        ...useWidgetToPosSingleSize_widget
        __typename
      }
      ... on WidgetRoutineOverview {
        ...WidgetRoutineOverview_widgetRoutineOverview
        ...useWidgetToPosSingleSize_widget
        __typename
      }
      ... on WidgetRoutinePositivePercentage {
        ...WidgetRoutinePositivePercentage_widgetRoutinePositivePercentage
        ...useWidgetToPosSingleSize_widget
        __typename
      }
      ... on WidgetIssueOverview {
        ...IssueFocusScreen_widgetIssueOverview
        __typename
      }
      ... on WidgetIntegrationIpool {
        ...WidgetIpoolIntegration_widgetIntegrationIpool
        ...useWidgetToPosSingleSize_widget
        __typename
      }
      ... on WidgetIntegrationPushAppy {
        webDashboardLink
        __typename
      }
      ... on WidgetIntegrationTurnpike {
        ...WidgetIntegrationTurnpike_widgetIntegrationTurnpike
        turnpikeSize
        __typename
      }
      ... on WidgetCalendar {
        ...WidgetCalendar_widgetCalendar
        __typename
      }
      ... on File {
        ...FileFocusScreen_file
        __typename
      }
    }
  }
`;
function noOp() {}
function WidgetFocus(props: {
  query: PreloadedQuery<FocusSingleWidgetQuery>;
  subject: FocusType | null;
}) {
  const data = usePreloadedQuery<FocusSingleWidgetQuery>(
    FOCUS_QUERY,
    props.query
  );

  const detailId = props.subject?.detailItemId ?? undefined;

  switch (data.node?.__typename) {
    case "WidgetEmployeeSchedule":
      return (
        <FocusModalWrapper>
          <WidgetWrapper forceScale={1} w={6} h={4}>
            <WidgetEmployeeSchedule widget={data.node} mode="IDLE" />
          </WidgetWrapper>
        </FocusModalWrapper>
      );
    case "WidgetFile":
      return (
        <FocusModalWrapper>
          <WidgetWrapper w={6} h={4} forceScale={1}>
            <WidgetFileStatic
              isFocusMode
              widget={data.node}
              pageId={props.subject.detailItemId}
            />
          </WidgetWrapper>
        </FocusModalWrapper>
      );

    case "WidgetNote":
      return (
        <FocusModalWrapper>
          <WidgetWrapper
            forceScale={data.node.noteSize === "TWO_X_ONE" ? 3 : 2}
            w={2}
            h={data.node.noteSize === "TWO_X_ONE" ? 1 : 2}
          >
            <WidgetNote
              idiom="STATIC"
              widget={data.node}
              pageId={detailId}
              mode="IDLE"
            />
          </WidgetWrapper>
        </FocusModalWrapper>
      );
    case "WidgetIssueOverview":
      return (
        <FocusModalWrapper>
          <IssueFocusScreen
            widget={data.node}
            issueId={detailId}
            onSelectDetailItem={noOp}
          />
        </FocusModalWrapper>
      );
    case "WidgetTodoOverview":
      return (
        <FocusModalWrapper>
          <TodoFocusScreen
            widget={data.node}
            todoId={detailId}
            onSelectDetailItem={noOp}
          />
        </FocusModalWrapper>
      );

    case "WidgetTeamSchedule":
      if (data.node) {
        return (
          <FocusModalWrapper>
            <WidgetWrapper forceScale={1} w={8} h={4}>
              <TeamScheduleFocusScreen widget={data.node} />
            </WidgetWrapper>
          </FocusModalWrapper>
        );
      }

      return data.node ? (
        <FocusModalWrapper>
          <WidgetWrapper forceScale={1} w={3} h={4}>
            <WidgetTeamSchedule widget={data.node} mode="IDLE" />
          </WidgetWrapper>
        </FocusModalWrapper>
      ) : null;

    case "WidgetIntegrationIpool":
      return data.node ? (
        <FocusModalWrapper>
          <WidgetWrapper forceScale={1} w={6} h={4}>
            <WidgetIpoolIntegration widget={data.node} mode="IDLE" />
          </WidgetWrapper>
        </FocusModalWrapper>
      ) : null;

    case "WidgetIntegrationPushAppy":
      return data.node ? (
        <FocusModalWrapper>
          <PushAppyFocusScreen webLink={data.node.webDashboardLink} />
        </FocusModalWrapper>
      ) : null;

    case "WidgetRoutinePositivePercentage":
      return (
        <FocusModalWrapper>
          <WidgetWrapper h={1} w={2}>
            <WidgetRoutinePositivePercentage widget={data.node} mode="IDLE" />
          </WidgetWrapper>
        </FocusModalWrapper>
      );

    case "WidgetWeather":
      return (
        <FocusModalWrapper>
          <WidgetWrapper forceScale={2.5} w={2} h={1}>
            <WidgetWeather
              clientForceSize="TWO_X_ONE"
              widget={data.node}
              mode="IDLE"
            />
          </WidgetWrapper>
        </FocusModalWrapper>
      );

    case "WidgetTaskCollection":
      return (
        <FocusModalWrapper>
          <WidgetWrapper h={1} w={2}>
            <WidgetTaskCollection widget={data.node} mode="IDLE" />
          </WidgetWrapper>
        </FocusModalWrapper>
      );
    case "WidgetTaskSingle":
      return (
        <FocusModalWrapper>
          <WidgetWrapper h={2} w={3}>
            <WidgetTaskSingle
              clientForceSize="TWO_X_ONE"
              maxOccasions={16}
              maxParts={16}
              widget={data.node}
              mode="IDLE"
            />
          </WidgetWrapper>
        </FocusModalWrapper>
      );
    case "WidgetIssueSummary":
      return (
        <FocusModalWrapper>
          <WidgetWrapper forceScale={1} w={3} h={4}>
            <WidgetIssueSummary widget={data.node} mode="IDLE" />
          </WidgetWrapper>
        </FocusModalWrapper>
      );

    case "WidgetCalendar":
      return (
        <FocusModalWrapper>
          <WidgetWrapper w={3} h={4} forceScale={1}>
            <WidgetCalendar widget={data.node} mode="IDLE" />
          </WidgetWrapper>
        </FocusModalWrapper>
      );

    case "WidgetIntegrationTurnpike":
      return (
        <FocusModalWrapper>
          <WidgetWrapper w={2} h={2}>
            <WidgetIntegrationTurnpike
              forceClientSize="TWO_X_TWO"
              widget={data.node}
              mode="IDLE"
            />
          </WidgetWrapper>
        </FocusModalWrapper>
      );
    case "File":
      if (!detailId) {
        return null;
      }
      return (
        <FocusModalWrapper>
          <FileFocusScreen
            file={data.node}
            pageId={detailId}
            scale={props.subject?.scale ?? undefined}
            transformX={props.subject?.transformX ?? undefined}
            transformY={props.subject?.transformY ?? undefined}
          />
        </FocusModalWrapper>
      );

    default:
      return null;
  }
}

export const StorecastFocus = (props: {
  storecastId: string;
  shouldAnalyze: boolean;
}) => {
  const [query, loadQuery, disposeQuery] =
    useQueryLoader<FocusSingleWidgetQuery>(FOCUS_QUERY);
  const [subject, setSubject] = useState<null | FocusSubscription$data>(null);

  const sessionId = subject?.storecastFocus?.sessionId;

  const hasFocus = !!subject?.storecastFocus?.subject;

  useEffect(() => {
    if (hasFocus && props.shouldAnalyze) {
      try {
        replay?.start();
      } catch (e) {}
    }
  }, [hasFocus, props.shouldAnalyze]);

  const [ack] = useMutation<FocusTVAckMutation>(graphql`
    mutation FocusTVAckMutation($sessionId: ID!) {
      ackFocus(sessionId: $sessionId)
    }
  `);

  useEffect(() => {
    if (sessionId) {
      ack({
        variables: {
          sessionId,
        },
      });
    }
  }, [ack, sessionId]);

  useSubscription<FocusSubscription>(
    useMemo(
      () => ({
        variables: {
          storecastId: props.storecastId,
        },

        onNext: (data) => {
          if (data?.storecastFocus?.subject.nodeId) {
            if (
              query?.variables.nodeId !== data?.storecastFocus?.subject?.nodeId
            ) {
              loadQuery({
                nodeId: data?.storecastFocus?.subject?.nodeId,
              });
            }
            setSubject(data ?? null);
          } else {
            disposeQuery();
            setSubject(null);
          }
        },
        subscription: graphql`
          subscription FocusSubscription($storecastId: ID!) {
            storecastFocus(storecastId: $storecastId) {
              setAt
              sessionId
              subject {
                detailItemId
                nodeId
                scale
                transformX
                transformY
              }
            }
          }
        `,
      }),
      [disposeQuery, loadQuery, props.storecastId, query?.variables.nodeId]
    )
  );

  return (
    <Suspense fallback={null}>
      {query ? (
        <WidgetFocus
          query={query}
          subject={subject?.storecastFocus?.subject ?? null}
        />
      ) : null}
    </Suspense>
  );
};
