import {
  AHQueryHandlerLoading,
  AHText,
  Box,
  ScaleFactorProvider,
  useScaleFactor,
} from "arkh";
import { StyleSheet } from "react-native";

import React, { useMemo, useRef, Suspense, useEffect } from "react";

import { useFragment, graphql } from "react-relay";

import { TodoFocusScreen_widgetTodoOverview$key } from "./__generated__/TodoFocusScreen_widgetTodoOverview.graphql";
import { TodoFocusScreenSrolledSubscription } from "./__generated__/TodoFocusScreenSrolledSubscription.graphql";
import { useSubscriptionWithSkip } from "widgets/src/utils/useSubscriptionWithSkip";
import {
  WidgetTodoOverviewList,
  WidgetTodoOverviewListRef,
} from "widgets/src/WidgetTodoOverview/WidgetTodoOverviewList";
import { TodoFocusScreenListHeader_widgetTodoOverview$key } from "./__generated__/TodoFocusScreenListHeader_widgetTodoOverview.graphql";
import { TodoDetail } from "./TodoDetail";
const noOp = () => {};
export const TodoFocusScreen = (props: {
  widget: TodoFocusScreen_widgetTodoOverview$key;
  todoId?: string;
  onSelectDetailItem: (id: string) => void;
}) => {
  const widget = useFragment(
    graphql`
      fragment TodoFocusScreen_widgetTodoOverview on WidgetTodoOverview {
        id
        ...TodoFocusScreenListHeader_widgetTodoOverview
        ...WidgetTodoOverviewListPaginated
      }
    `,
    props.widget
  );

  const sf = useScaleFactor();
  const flRef = useRef<WidgetTodoOverviewListRef>(null);

  const [delayedIssueId, setDelayedIssueId] = React.useState<
    string | undefined
  >(props.todoId);
  useEffect(() => {
    setDelayedIssueId(props.todoId);
  }, [props.todoId]);
  useSubscriptionWithSkip<TodoFocusScreenSrolledSubscription>(
    useMemo(
      () => ({
        subscription: graphql`
          subscription TodoFocusScreenSrolledSubscription($widgetId: ID!) {
            widgetFocusScrollChanged(widgetId: $widgetId)
          }
        `,
        variables: { widgetId: widget.id },
        onNext: (data) => {
          flRef.current?.scrollToItem(data?.widgetFocusScrollChanged ?? null);
        },
      }),
      [widget.id]
    )
  );

  return (
    <Box space="xxlarge" style={{ flex: 1, width: "80%", alignSelf: "center" }}>
      <Box
        style={{
          flex: 1,
          flexDirection: "row",
          overflow: "hidden",
        }}
        cornerRadius="medium"
        color="surface"
      >
        <Box
          style={{
            flexBasis: "30%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Suspense fallback={null}>
            <ListHeader widget={widget} />
          </Suspense>
          <WidgetTodoOverviewList
            ref={flRef}
            widget={widget}
            isFocused
            mode="FOCUS"
            hideShowMoreButton
            onPressItem={props.onSelectDetailItem}
            setScroll={noOp}
            interactive
            highlightedItemId={delayedIssueId}
          />
        </Box>
        {props.todoId ? (
          <Box
            style={{
              flexBasis: "70%",
              borderLeftWidth: StyleSheet.hairlineWidth,
            }}
            borderColor="surfaceBorder"
          >
            <ScaleFactorProvider value={sf * 1.5}>
              <Suspense fallback={<AHQueryHandlerLoading />}>
                <TodoDetail todoId={props.todoId} />
              </Suspense>
            </ScaleFactorProvider>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

function ListHeader(props: {
  widget: TodoFocusScreenListHeader_widgetTodoOverview$key;
}) {
  const widget = useFragment(
    graphql`
      fragment TodoFocusScreenListHeader_widgetTodoOverview on WidgetTodoOverview {
        category {
          title
        }
        teams {
          name
        }
      }
    `,
    props.widget
  );

  return (
    <Box space="small" spaceBottom="large">
      <AHText type="title">{widget.category?.title}</AHText>
      <AHText type="detail">
        {widget.teams.map(({ name }) => name).join(", ")}
      </AHText>
    </Box>
  );
}
