/**
 * @generated SignedSource<<a43f7835010564f2ee361b57ae6a0da4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TodoDetailImages_todo$data = {
  readonly images: ReadonlyArray<{
    readonly size: {
      readonly url: string;
    } | null;
  }>;
  readonly " $fragmentType": "TodoDetailImages_todo";
};
export type TodoDetailImages_todo$key = {
  readonly " $data"?: TodoDetailImages_todo$data;
  readonly " $fragmentSpreads": FragmentRefs<"TodoDetailImages_todo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TodoDetailImages_todo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "minHeight",
              "value": 699
            },
            {
              "kind": "Literal",
              "name": "minWidth",
              "value": 699
            }
          ],
          "concreteType": "ImageSize",
          "kind": "LinkedField",
          "name": "size",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": "size(minHeight:699,minWidth:699)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Todo",
  "abstractKey": null
};

(node as any).hash = "8fd98cc1f0a19138a2d787b756c89dbe";

export default node;
