import { useEffect, useState } from "react";

export const enum SubscriptionStatus {
  init,
  connected,
  closed,
  connecting,
  refetching,
}
export let subscriptionStatus: SubscriptionStatus = SubscriptionStatus.init;
const subscribeFns: ((status: SubscriptionStatus) => void)[] = [];

export const setSubscriptionStatus = (status: SubscriptionStatus) => {
  subscriptionStatus = status;
  subscribeFns.forEach((fn) => fn(status));
};

const subscribeToStatus = (
  onNewStatus: (newStatus: SubscriptionStatus) => any
) => {
  subscribeFns.push(onNewStatus);
  const len = subscribeFns.length;
  return () => {
    delete subscribeFns[len - 1];
  };
};

export const useSubscriptionConnectionStatus = () => {
  const [status, setStatus] = useState(subscriptionStatus);
  useEffect(() => {
    return subscribeToStatus(setStatus);
  }, []);

  return status;
};
