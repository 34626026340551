import { Interpreter } from "xstate";
export const refreshAuth = async (
  authService: Interpreter<Partial<{ authToken: string }>, any, any, any, any>
): Promise<string> => {
  const initialAuthToken = authService.state.context.authToken;

  const res = new Promise<string>((resolve, reject) => {
    const sub = authService.subscribe((k) => {
      if (!k.matches("loggedIn")) {
        sub?.unsubscribe();
        reject(new Error());
      } else if (
        k.matches("loggedIn.ready") &&
        k.context.authToken !== initialAuthToken &&
        k.context.authToken
      ) {
        sub?.unsubscribe();
        resolve(k.context.authToken);
      }
    });
  });
  if (authService.state.matches("loggedIn.ready" as any)) {
    authService.send("REFRESH_TOKEN");
  }
  return res;
};
