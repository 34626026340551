import { AHText, AHTextFonts, Box } from "arkh";
import React, { useMemo } from "react";
import { MasonryImageGrid } from "../../../common/MasonryImageGrid";
import { DateTime } from "luxon";
import { InfoElement } from "../InfoElement";
import { ResolvedCircle } from "widgets/src/WidgetIssueOverview/ResolvedCircle";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";

import { useSubscriptionWithSkip } from "widgets/src/utils/useSubscriptionWithSkip";
import { IssueFocusDetailSubscription } from "./__generated__/IssueFocusDetailSubscription.graphql";
import { IssueFocusDetailQuery } from "./__generated__/IssueFocusDetailQuery.graphql";
import { IssueFocusDetailComponent_issue$key } from "./__generated__/IssueFocusDetailComponent_issue.graphql";
import { FormattedMessage } from "react-intl";

export function IssueFocusDetail(props: { issueId: string }) {
  const { issue } = useLazyLoadQuery<IssueFocusDetailQuery>(
    graphql`
      query IssueFocusDetailQuery($issueId: ID!) {
        issue(id: $issueId) @required(action: THROW) {
          id
          ...IssueFocusDetailComponent_issue
        }
      }
    `,
    { issueId: props.issueId }
  );

  useSubscriptionWithSkip<IssueFocusDetailSubscription>(
    useMemo(
      () => ({
        variables: { issueId: issue.id },
        subscription: graphql`
          subscription IssueFocusDetailSubscription($issueId: ID!) {
            issuesChangedByIds(issueIds: [$issueId]) {
              ...IssueFocusDetailComponent_issue
            }
          }
        `,
      }),
      [issue.id]
    )
  );

  return <IssueFocusComponent issue={issue} />;
}

function IssueFocusComponent(props: {
  issue: IssueFocusDetailComponent_issue$key;
}) {
  const issue = useFragment(
    graphql`
      fragment IssueFocusDetailComponent_issue on Issue {
        id
        resolved
        createdAt
        title
        assignedTeams {
          id
          name
        }
        taskExecution {
          sectionVersion {
            title
          }
        }
        task {
          title
          routine {
            title
            team {
              name
            }
          }
        }
        images {
          size(minWidth: 699, minHeight: 699) {
            url
          }
        }
      }
    `,
    props.issue
  );

  const images = useMemo(
    () =>
      issue.images?.flatMap((img) =>
        img.size?.url ? [{ url: img.size.url }] : []
      ) ?? [],
    [issue.images]
  );

  return (
    <Box
      color="surface"
      spaceHorizontal="medium"
      spaceVertical="small"
      style={{ flex: 1, transform: [{ rotateZ: "0deg" }] }}
    >
      <Box style={{ flexDirection: "row", alignItems: "center" }}>
        <AHText type="title-small" style={{ flex: 1 }}>
          {issue.task.routine.title}
        </AHText>
        <AHText type="detail">{issue.task.routine.team.name}</AHText>
      </Box>
      <Box
        style={{ flexDirection: "row", alignItems: "center" }}
        spaceVertical="small"
      >
        <ResolvedCircle isResolved={issue.resolved} />
        <Box style={{ flex: 1 }} spaceLeft="xsmall">
          <AHText
            type="title"
            color="darkerText"
            style={{ flex: 1, fontFamily: "system-regular" }}
          >
            <AHText type="title">{issue.task.title} </AHText>
            {issue.title ?? "Avvikelse"}
          </AHText>
        </Box>
        <AHText type="detail" font={AHTextFonts.BOLD}>
          {issue.taskExecution.sectionVersion.title}
        </AHText>
      </Box>
      <Box spaceBottom="small">
        <InfoElement
          iconType="created-at"
          title={
            <FormattedMessage
              description="IssueFocusDetail created at label"
              defaultMessage="Created"
            />
          }
          content={DateTime.fromISO(issue.createdAt).toLocaleString(
            DateTime.DATETIME_MED_WITH_WEEKDAY
          )}
        />
        {issue.assignedTeams !== null &&
        (issue.assignedTeams ?? []).length > 0 ? (
          <InfoElement
            iconType="team"
            title={
              <FormattedMessage
                description="IssueFocusDetail assigned teams label"
                defaultMessage="Assigned Teams"
              />
            }
            content={issue.assignedTeams.map(({ name }) => name).join(", ")}
          />
        ) : null}
      </Box>
      <MasonryImageGrid images={images} />
    </Box>
  );
}
