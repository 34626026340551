/**
 * @generated SignedSource<<36bb11c7e3ee77c28835e618b28ea7a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Locale = "en" | "nb" | "sv";
export type MainLocaleQuery$variables = {};
export type MainLocaleQuery$data = {
  readonly store: {
    readonly locale: Locale;
    readonly timezone: string;
  };
};
export type MainLocaleQuery = {
  response: MainLocaleQuery$data;
  variables: MainLocaleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locale",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MainLocaleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MainLocaleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b53f9636c71e16ceaf59417a3b37444",
    "id": null,
    "metadata": {},
    "name": "MainLocaleQuery",
    "operationKind": "query",
    "text": "query MainLocaleQuery {\n  store {\n    locale\n    timezone\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4bcfb54838b73e7f5b8b4a2fb818b77";

export default node;
