/**
 * @generated SignedSource<<8d5b6b562ad6ab2a0d6dec44a10110f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorecastLayoutChangedTVSubscription$variables = {
  storecastId: string;
};
export type StorecastLayoutChangedTVSubscription$data = {
  readonly widgetsRearranged: {
    readonly id: string;
    readonly widgets: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"useWidgetToPos_widget">;
    }>;
  };
};
export type StorecastLayoutChangedTVSubscription = {
  response: StorecastLayoutChangedTVSubscription$data;
  variables: StorecastLayoutChangedTVSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "storecastId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "storecastId",
    "variableName": "storecastId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "positionX",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "positionY",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StorecastLayoutChangedTVSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dashboard",
        "kind": "LinkedField",
        "name": "widgetsRearranged",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "widgets",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useWidgetToPos_widget"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StorecastLayoutChangedTVSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dashboard",
        "kind": "LinkedField",
        "name": "widgetsRearranged",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "widgets",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isWidget"
              },
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overviewSize",
                    "storageKey": null
                  }
                ],
                "type": "WidgetOverview",
                "abstractKey": "__isWidgetOverview"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "noteSize",
                    "storageKey": null
                  }
                ],
                "type": "WidgetNote",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taskSingleSize",
                    "storageKey": null
                  }
                ],
                "type": "WidgetTaskSingle",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  }
                ],
                "type": "WidgetWeather",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "turnpikeSize",
                    "storageKey": null
                  }
                ],
                "type": "WidgetIntegrationTurnpike",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "subWidgets",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "height",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "WidgetSpace",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "18a18db6dbf8d93ed1012146241fabe8",
    "id": null,
    "metadata": {},
    "name": "StorecastLayoutChangedTVSubscription",
    "operationKind": "subscription",
    "text": "subscription StorecastLayoutChangedTVSubscription(\n  $storecastId: ID!\n) {\n  widgetsRearranged(storecastId: $storecastId) {\n    id\n    widgets {\n      __typename\n      ...useWidgetToPos_widget\n      id\n    }\n  }\n}\n\nfragment useWidgetToPos_widget on Widget {\n  __isWidget: __typename\n  id\n  positionX\n  positionY\n  ... on WidgetOverview {\n    __isWidgetOverview: __typename\n    overviewSize\n  }\n  ... on WidgetNote {\n    noteSize\n  }\n  ... on WidgetTaskSingle {\n    taskSingleSize\n  }\n  ... on WidgetWeather {\n    size\n  }\n  ... on WidgetIntegrationTurnpike {\n    turnpikeSize\n  }\n  ... on WidgetSpace {\n    subWidgets {\n      __typename\n      id\n      positionX\n      positionY\n      height\n    }\n  }\n  __typename\n}\n"
  }
};
})();

(node as any).hash = "3eddc4b7b6a61680be32772c3edbfc08";

export default node;
