/**
 * @generated SignedSource<<da1af0df943d197353a147cd6830eb84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IssueFocusDetailComponent_issue$data = {
  readonly assignedTeams: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null;
  readonly createdAt: string;
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly size: {
      readonly url: string;
    } | null;
  }> | null;
  readonly resolved: boolean;
  readonly task: {
    readonly routine: {
      readonly team: {
        readonly name: string;
      };
      readonly title: string;
    };
    readonly title: string;
  };
  readonly taskExecution: {
    readonly sectionVersion: {
      readonly title: string | null;
    };
  };
  readonly title: string | null;
  readonly " $fragmentType": "IssueFocusDetailComponent_issue";
};
export type IssueFocusDetailComponent_issue$key = {
  readonly " $data"?: IssueFocusDetailComponent_issue$data;
  readonly " $fragmentSpreads": FragmentRefs<"IssueFocusDetailComponent_issue">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IssueFocusDetailComponent_issue",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resolved",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Team",
      "kind": "LinkedField",
      "name": "assignedTeams",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "taskExecution",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoutineSectionVersion",
          "kind": "LinkedField",
          "name": "sectionVersion",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Routine",
          "kind": "LinkedField",
          "name": "routine",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Team",
              "kind": "LinkedField",
              "name": "team",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "minHeight",
              "value": 699
            },
            {
              "kind": "Literal",
              "name": "minWidth",
              "value": 699
            }
          ],
          "concreteType": "ImageSize",
          "kind": "LinkedField",
          "name": "size",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": "size(minHeight:699,minWidth:699)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Issue",
  "abstractKey": null
};
})();

(node as any).hash = "b4dbd97143c17652ede0fdb32fe495a5";

export default node;
