import { DateTime } from "luxon";
import { useCallback, useEffect } from "react";
import { Dimensions } from "react-native";
import { graphql, useMutation } from "react-relay";
import * as Sentry from "sentry-expo";
import { useInterval } from "widgets/useInterval";

import { currentLatency } from "../../Client";
import { registerAliveMutation } from "./__generated__/registerAliveMutation.graphql";

export const useRegisterAliveDevice = async (screenId: string) => {
  useEffect(() => {
    Sentry.Browser.setUser({
      id: screenId,
    });
  }, [screenId]);
  const [mutate] = useMutation<registerAliveMutation>(graphql`
    mutation registerAliveMutation(
      $screenId: ID!
      $latency: Int
      $screenWidth: Int
      $screenHeight: Int
      $timeZone: String
      $clientTime: String
    ) {
      storecast {
        registerAliveDevice(
          screenId: $screenId
          latency: $latency
          screenWidth: $screenWidth
          screenHeight: $screenHeight
          timeZone: $timeZone
          clientTime: $clientTime
        )
      }
    }
  `);

  const notifyAlive = useCallback(() => {
    const screen = Dimensions.get("screen");
    mutate({
      variables: {
        screenId,
        screenWidth: screen.width,
        screenHeight: screen.height,
        timeZone: DateTime.local().zoneName,
        latency: currentLatency ? Math.floor(currentLatency) : undefined,
        clientTime: DateTime.local().toISO(),
      },
      onError: (e) => {
        console.log("failed to register device alive");
        Sentry.Browser.captureException(e);
      },
    });
  }, [mutate, screenId]);

  useEffect(() => {
    notifyAlive();
  }, [notifyAlive, screenId]);

  useInterval(() => {
    notifyAlive();
  }, 60000);
};
