/**
 * @generated SignedSource<<abb958ba5797e2cac0290e05626fa462>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type TodoFocusScreenSrolledSubscription$variables = {
  widgetId: string;
};
export type TodoFocusScreenSrolledSubscription$data = {
  readonly widgetFocusScrollChanged: string;
};
export type TodoFocusScreenSrolledSubscription = {
  response: TodoFocusScreenSrolledSubscription$data;
  variables: TodoFocusScreenSrolledSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "widgetId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "widgetId",
        "variableName": "widgetId"
      }
    ],
    "kind": "ScalarField",
    "name": "widgetFocusScrollChanged",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TodoFocusScreenSrolledSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TodoFocusScreenSrolledSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "41b28c7914f834d51c40a543a06d780a",
    "id": null,
    "metadata": {},
    "name": "TodoFocusScreenSrolledSubscription",
    "operationKind": "subscription",
    "text": "subscription TodoFocusScreenSrolledSubscription(\n  $widgetId: ID!\n) {\n  widgetFocusScrollChanged(widgetId: $widgetId)\n}\n"
  }
};
})();

(node as any).hash = "4d3093c8cc638d95f3f6334b4ede932e";

export default node;
