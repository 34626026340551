import { Box } from "arkh";
import React from "react";
import { useUnitSize } from "../../../OS/UnitSizeProvider";

export function PushAppyFocusScreen(props: { webLink: string }) {
  const unitSize = useUnitSize();

  return (
    <Box
      space="xxlarge"
      style={{ flex: 1, width: "100%", alignSelf: "center" }}
    >
      <Box
        style={{
          flex: 1,

          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
        cornerRadius="medium"
      >
        <iframe
          src={props.webLink}
          height={unitSize * 4}
          width={unitSize * 7}
        />
      </Box>
    </Box>
  );
}
