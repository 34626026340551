import { AHIcon, AHIconType, AHText, AHTextFonts, Box } from "arkh";
import React from "react";

export function InfoElement(props: {
  title: React.ReactNode;
  iconType: AHIconType;
  content: React.ReactNode;
}) {
  return (
    <Box
      style={{ flexDirection: "row", alignItems: "center" }}
      spaceBottom="xxsmall"
    >
      <AHIcon
        size="body"
        color="darkerText"
        spaceHorizontal="xsmall"
        type={props.iconType}
      />
      <AHText color="darkerText" font={AHTextFonts.BOLD}>
        {props.title}:
      </AHText>
      <AHText type="body" color="darkerText" spaceLeft="xxsmall">
        {props.content}
      </AHText>
    </Box>
  );
}
