import * as Sentry from "@sentry/browser";

import { authState } from "./machine";
import { interpret } from "xstate";

export const authService = interpret(authState);
export type AuthServiceT = typeof authService;

authService.onTransition((state) => {
  if (state.value) {
    console.log("STATE_TRANSITION", state.value);
    Sentry.addBreadcrumb({
      type: "auth.state.change",
      message: JSON.stringify(state.value),
    });
  }
});
authService.start();
