import * as Sentry from "@sentry/browser";
import { config } from "../../../config";
import { serializeJSON } from "./serializeJSON";

type Res =
  | {
      success: true;
      authToken: string;
      idToken: string;
      refreshToken?: string;
    }
  | { success: false };

export async function refreshToken(
  refreshToken: string,
  deviceId?: string,
  authToken?: string
): Promise<Res> {
  try {
    const newToken = refreshToken.startsWith("rt-v1");
    const domain = config.baseUrl + "/storecast";

    const response = await fetch(`${domain}/oauth/token`, {
      method: "post",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: serializeJSON({
        client_id: config.clientId,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
        device_id: newToken ? undefined : deviceId,
        auth_token: newToken ? undefined : authToken,
      }),
    }).catch(() => null);

    if (!response) {
      return { success: false }; // network error retry refreshToken
    }
    if (response.status >= 500) {
      return { success: false }; // server error retry refreshToken
    }

    const res = await response.json();
    if (
      res.access_token &&
      res.id_token &&
      res.refresh_token &&
      typeof res.access_token === "string" &&
      typeof res.id_token === "string"
    ) {
      if (res.refresh_token && typeof res.refresh_token === "string") {
        return {
          success: true,
          authToken: res.access_token as string,
          idToken: res.id_token as string,
          refreshToken: res.refresh_token as string,
        };
      } else {
        return {
          success: true,
          authToken: res.access_token as string,
          idToken: res.id_token as string,
        };
      }
    } else {
      throw new Error("response did have access_token, id_token");
    }
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
}

export async function refreshTokenMock(
  mode: "network_error" | "error" | "success",
  refreshToken?: string,
  authToken?: string
): Promise<Res> {
  if (mode === "network_error") {
    return { success: false };
  }
  if (mode === "error") {
    throw new Error();
  }
  return {
    authToken: authToken ?? "refreshedAt",
    idToken: "refreshedIdToken",
    refreshToken: refreshToken ?? "refreshedRefreshToken",
    success: true,
  };
}
