import { Box } from "arkh";
import React from "react";
import { graphql, useFragment } from "react-relay";

import { useUnitSize } from "../../../OS/UnitSizeProvider";
import { FileFocusScreen_file$key } from "./__generated__/FileFocusScreen_file.graphql";

export function FileFocusScreen(props: {
  file: FileFocusScreen_file$key;
  pageId: string;
  transformX?: number;
  transformY?: number;
  scale?: number;
}) {
  const unitSize = useUnitSize();

  const file = useFragment(
    graphql`
      fragment FileFocusScreen_file on File {
        id
        pages {
          id
          image {
            size {
              url
            }
          }
        }
      }
    `,
    props.file
  );

  const page = file.pages.find((p) => p.id === props.pageId);

  if (!page?.image?.size?.url) {
    return null;
  }
  const transformX = props.transformX ?? 0.5;
  const transformY = props.transformY ?? 0.5;
  const scale = props.scale ?? 1;
  return (
    <Box
      space="xxlarge"
      style={{ flex: 1, width: "100%", alignSelf: "center" }}
    >
      <Box
        style={{
          flex: 1,

          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
        cornerRadius="medium"
      >
        <img
          style={{
            height: unitSize * 4,
            transition: "0.5s",
            justifyContent: "center",
            borderRadius: 10,
            transformOrigin: `${transformX * 100}% ${transformY * 100}% `,
            transform: `scale(${scale > 1.1 ? scale * 0.9 : scale})`,
          }}
          src={page.image?.size?.url}
        />
      </Box>
    </Box>
  );
}
