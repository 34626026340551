import { AHIcon, AHText, Box } from "arkh";
import {
  SubscriptionStatus,
  useSubscriptionConnectionStatus,
} from "../../subscriptionStatus";

import React from "react";

const statusMessages = {
  [SubscriptionStatus.closed]:
    "Problem med anslutningen. Kontrollera att skärmen har internet.",
  [SubscriptionStatus.connecting]: "Återansluter.",
  [SubscriptionStatus.refetching]: "Hämtar ny data.",
};
export function InternetConnectionBar() {
  const status = useSubscriptionConnectionStatus();
  if (
    status === SubscriptionStatus.closed ||
    status === SubscriptionStatus.connecting ||
    status === SubscriptionStatus.refetching
  ) {
    return (
      <Box
        style={[
          {
            position: "absolute",
            left: 0,
            bottom: 0,
            alignItems: "center",
            flexDirection: "row",
          },
        ]}
        spaceLeft="medium"
        spaceBottom="xsmall"
      >
        <Box
          style={{ flexDirection: "row" }}
          spaceHorizontal="large"
          spaceVertical="medium"
          color="surface"
          cornerRadius="small"
        >
          <AHIcon color="negative" spaceRight="small" type="connection-error" />
          <AHText type="title">{statusMessages[status]}</AHText>
        </Box>
      </Box>
    );
  }
  return null;
}
