import * as Sentry from "@sentry/browser";

import { LOCAL_STORAGE_KEY } from "./LOCAL_STORAGE_KEY";
import { isValidLoggedInState } from "./isValidLoggedInState";

export const persistLocalState = (state: {
  authToken: string | undefined;
  currentStoreId: string | undefined;
  idToken: string | undefined;

  refreshToken: string | undefined;
}) => {
  try {
    if (isValidLoggedInState(state)) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({ ...state, lastPersistedAt: new Date().toISOString() })
      );
    } else {
      Sentry.withScope((s) => {
        s.setExtra("state", state);
        Sentry.captureMessage("Invalid state to persist");
      });
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};
