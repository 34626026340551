import * as Sentry from "sentry-expo";

import { AHText, ScaleFactorProvider } from "arkh";
import React, { PropsWithChildren } from "react";
import { View, useWindowDimensions } from "react-native";

import { ErrorBoundary } from "react-error-boundary";
import { FontLoader } from "../common/FontLoader";
import { Main } from "./Main";
import { UnitSizeProvider } from "./UnitSizeProvider";

import { IntlProvider } from "react-intl";
import { useGuessLocale } from "./useGuessLocale";
import { getLocaleMessages } from "./getLocaleMessages";

const BackgroundWrapper = (props: PropsWithChildren<object>) => {
  //  const theme = useTheme();
  return (
    <View
      style={{
        backgroundColor: "#ededed",
        flex: 1,
        height: "100%",
      }}
    >
      {props.children}
    </View>
  );
};

function App() {
  const { height, width } = useWindowDimensions();
  const numColumns = 8;
  const unitSize = Math.min(width / numColumns, (height - 50) / 4 - 2) - 4;

  const guessLocale = useGuessLocale();
  return (
    <IntlProvider
      defaultLocale="en"
      locale={guessLocale}
      messages={getLocaleMessages(guessLocale)}
    >
      <ScaleFactorProvider value={unitSize / 236}>
        <UnitSizeProvider value={unitSize}>
          <BackgroundWrapper>
            <ErrorBoundary
              onError={(err) => {
                Sentry.Browser.captureException(err);
                setTimeout(() => {
                  location.reload();
                }, 8000);
              }}
              fallbackRender={(err) => {
                return (
                  <View>
                    <AHText type="body-big">Error {err.error?.name}</AHText>
                  </View>
                );
              }}
            >
              <View
                style={{
                  width: "100%",
                  height: "100%",
                  flex: 1,
                }}
              >
                <FontLoader>
                  <Main guessLocale={guessLocale} />
                </FontLoader>
              </View>
            </ErrorBoundary>
          </BackgroundWrapper>
        </UnitSizeProvider>
      </ScaleFactorProvider>
    </IntlProvider>
  );
}

export default App;
