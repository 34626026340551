/**
 * @generated SignedSource<<9b8a4e2c8c1fbf9b9afdff40966f193d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TodoDetailSubscription$variables = {
  todoId: string;
};
export type TodoDetailSubscription$data = {
  readonly todoChangedByIds: {
    readonly id: string;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"TodoCircleWidgetOverview_Todo" | "TodoDetailBodyAndTags_todo" | "TodoDetailImages_todo" | "TodoDetailInfoItems_todo" | "TodoDetailTeams_todo">;
  };
};
export type TodoDetailSubscription = {
  response: TodoDetailSubscription$data;
  variables: TodoDetailSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "todoId"
  }
],
v1 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "todoIds.0",
        "variableName": "todoId"
      }
    ],
    "kind": "ListValue",
    "name": "todoIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TodoDetailSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Todo",
        "kind": "LinkedField",
        "name": "todoChangedByIds",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TodoDetailBodyAndTags_todo"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TodoDetailTeams_todo"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TodoCircleWidgetOverview_Todo"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TodoDetailInfoItems_todo"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TodoDetailImages_todo"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TodoDetailSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Todo",
        "kind": "LinkedField",
        "name": "todoChangedByIds",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TodoCategoryTag",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "teams",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expireAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assigneeText",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TodoAssignedUser",
            "kind": "LinkedField",
            "name": "assignedUsers",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "minHeight",
                    "value": 699
                  },
                  {
                    "kind": "Literal",
                    "name": "minWidth",
                    "value": 699
                  }
                ],
                "concreteType": "ImageSize",
                "kind": "LinkedField",
                "name": "size",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": "size(minHeight:699,minWidth:699)"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4819a2f103fab3e09eb71e9116579e57",
    "id": null,
    "metadata": {},
    "name": "TodoDetailSubscription",
    "operationKind": "subscription",
    "text": "subscription TodoDetailSubscription(\n  $todoId: ID!\n) {\n  todoChangedByIds(todoIds: [$todoId]) {\n    id\n    title\n    ...TodoDetailBodyAndTags_todo\n    ...TodoDetailTeams_todo\n    ...TodoCircleWidgetOverview_Todo\n    ...TodoDetailInfoItems_todo\n    ...TodoDetailImages_todo\n  }\n}\n\nfragment TodoCircleWidgetOverview_Todo on Todo {\n  expireAt\n  createdAt\n  status\n}\n\nfragment TodoDetailBodyAndTags_todo on Todo {\n  body\n  tags {\n    id\n    color\n    title\n  }\n}\n\nfragment TodoDetailImages_todo on Todo {\n  images {\n    size(minHeight: 699, minWidth: 699) {\n      url\n      id\n    }\n    id\n  }\n}\n\nfragment TodoDetailInfoItems_todo on Todo {\n  createdBy {\n    name\n    id\n  }\n  assigneeText\n  assignedUsers {\n    user {\n      name\n      id\n    }\n    id\n  }\n  expireAt\n}\n\nfragment TodoDetailTeams_todo on Todo {\n  teams {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e3d4b305a6567040fdc0f44a467f82bb";

export default node;
