import { CloseCode, createClient } from "graphql-ws";

import {
  SubscriptionStatus,
  setSubscriptionStatus,
  subscriptionStatus,
} from "./subscriptionStatus";
import { getLocalizationAsync } from "expo-localization";
import { authService } from "./OS/auth/authService";

import { config } from "./config";

import * as Sentry from "@sentry/browser";
import { refreshAuth } from "./OS/auth/refreshAuth";

import {
  Environment,
  Observable,
  RecordSource,
  ROOT_TYPE,
  Store,
  SubscribeFunction,
  TaskScheduler,
} from "relay-runtime";
import {
  RelayNetworkLayer,
  urlMiddleware,
  retryMiddleware,
  authMiddleware,
  createRequestError,
} from "react-relay-network-modern";
import { unstable_batchedUpdates } from "react-dom";

export async function waitForSession(): Promise<{
  authToken: string;
  currentStoreId?: string;
}> {
  if (
    authService.state.matches("loggedIn") &&
    authService.state.context.authToken
  ) {
    return {
      authToken: authService.state.context.authToken,
      currentStoreId: authService.state.context.currentStoreId,
    };
  }

  return new Promise<{
    authToken: string;
    currentStoreId?: string;
  }>((resolve) => {
    const v = authService.subscribe((state) => {
      if (state.matches("loggedIn") && state.context.authToken) {
        v.unsubscribe();
        resolve({
          authToken: state.context.authToken,
          currentStoreId: state.context.authToken,
        });
      }
    });
  });
}

let activeSocket: WebSocket | undefined;
let timedOut: ReturnType<typeof setTimeout> | undefined;
export let currentLatency: number | undefined;
let lastLatency: number | undefined;
let lastPing = Date.now();

export function RelayEnvironment(locale: "sv" | "nb" | "en") {
  const subscriptionsClient = createClient({
    url: config.baseWSUrl + "/sub",
    keepAlive: 5000,
    retryAttempts: 1000,
    retryWait(retries) {
      return new Promise((resolve) =>
        setTimeout(resolve, 500 + retries * 500 * Math.random())
      );
    },
    shouldRetry: () => {
      return true;
    },
    connectionAckWaitTimeout: 10000,
    on: {
      connecting: () => {
        setSubscriptionStatus(SubscriptionStatus.connecting);
      },

      connected: (socket: any) => {
        setSubscriptionStatus(SubscriptionStatus.connected);
        if (
          activeSocket &&
          subscriptionStatus !== SubscriptionStatus.refetching
        ) {
          setSubscriptionStatus(SubscriptionStatus.connected);
        } else {
          setSubscriptionStatus(SubscriptionStatus.connected);
        }
        activeSocket = socket;
      },
      closed: (event: any) => {
        console.log("closed");
        lastLatency = undefined;
        setSubscriptionStatus(SubscriptionStatus.closed);
        if (event?.code === CloseCode.Forbidden) {
        }
      },

      ping: (received) => {
        if (!received && timedOut === undefined) {
          lastPing = Date.now();

          timedOut = setTimeout(() => {
            if (activeSocket?.readyState === WebSocket.OPEN) {
              setSubscriptionStatus(SubscriptionStatus.closed);
              activeSocket?.close(4408, "Request Timeout");
            }
          }, 6000); // wait 3 seconds for the pong and then close the connection
        }
      },
      pong: (received) => {
        if (received && timedOut) {
          const latency = Math.max(Math.min(Date.now() - lastPing, 5000), 0);
          // moving avarage with k=2
          lastLatency = currentLatency;
          currentLatency =
            ((currentLatency ?? latency) + (lastLatency ?? latency) + latency) /
            3;

          clearTimeout(timedOut);
          timedOut = undefined;
        } // pong is received, clear connection close timeout
      },
    },
    connectionParams: async () => {
      console.log("waiting for connection params");
      const session = await waitForSession();
      console.log("params recived");

      return {
        authToken: session.authToken,
        storeId: session.currentStoreId,
        language: locale,
      };
    },
  });

  const subFN: SubscribeFunction = (operation, variables) => {
    return Observable.create((sink) => {
      if (!operation.text) {
        return sink.error(new Error("Operation text cannot be empty"));
      }

      return subscriptionsClient.subscribe(
        {
          operationName: operation.name,
          query: operation.text,
          variables,
        },
        sink as any
      );
    });
  };

  const network = new RelayNetworkLayer(
    [
      urlMiddleware({
        url: (req) => {
          console.log("🌐🌐 ", req.operation.name);
          return Promise.resolve(config.baseUrl + "/graphql");
        },
        headers: async (): Promise<{
          "x-storesprint-store-id": string;
          "x-storesprint-locale": string;
          "accept-language": string;
        }> => {
          const session = await waitForSession();
          return {
            "x-storesprint-store-id": session?.currentStoreId ?? "",
            "accept-language": locale,
            "x-storesprint-locale": locale,
          };
        },
      }),

      (next) => async (req) => {
        Sentry.addBreadcrumb({ type: "gql.query", data: { id: req.getID() } });
        try {
          const res = await next(req);
          const errorCodes: string[] =
            res?.errors?.map((e) => (e as any)?.extensions?.code) ?? [];

          if (errorCodes.includes("STORECAST_SCREEN_DEVICE_UNDEFINED")) {
            authService.send("STORECAST_SCREEN_DEVICE_UNDEFINED");
          }
          if (errorCodes.includes("UNAUTHENTICATED")) {
            authService.send("STORECAST_SCREEN_DEVICE_UNDEFINED");
          }

          if (res.errors) {
            Sentry.addBreadcrumb({ type: "gql.error", data: { errorCodes } });
            const error = createRequestError(req, res);
            Sentry.captureException(error);
          } else {
            Sentry.addBreadcrumb({ type: "gql.success" });
          }

          return res;
        } catch (e) {
          Sentry.captureException(e);
          throw e;
        }
      },
      retryMiddleware({
        fetchTimeout: 15000,
        retryDelays: [500, 3000, 5000, 11000],
        statusCodes: [429, 500, 503, 504],
      }),
      authMiddleware({
        token: async () => {
          const session = await waitForSession();
          return session.authToken;
        },

        tokenRefreshPromise: async () => {
          return refreshAuth(authService).then((authToken) => {
            return authToken;
          });
        },
      }),
    ],
    { subscribeFn: subFN as any }
  ); // as second arg you may pass advanced options for RRNL

  const source = new RecordSource();
  const relayStore = new Store(source);

  const RelayScheduler: TaskScheduler = {
    cancel: () => {},
    schedule: (task) => {
      unstable_batchedUpdates(task);
      return "";
    },
  };
  const env = new Environment({
    network,
    scheduler: RelayScheduler,
    store: relayStore,
    missingFieldHandlers: [
      {
        kind: "linked",
        handle(field, record, argValues, store) {
          if (
            record != null &&
            record.__typename === ROOT_TYPE &&
            field.name === "node" &&
            argValues.hasOwnProperty("id")
          ) {
            return argValues.id;
          }
          return undefined;
        },
      },
      {
        kind: "linked",
        handle(field, record, argValues, store) {
          if (
            record != null &&
            record.__typename === "Storecast" &&
            field.name === "screen" &&
            argValues.hasOwnProperty("id")
          ) {
            return argValues.id;
          }

          return undefined;
        },
      },
      {
        kind: "linked",
        handle(field, record, argValues, store) {
          if (
            record != null &&
            record.__typename === ROOT_TYPE &&
            field.name === "routine" &&
            argValues.hasOwnProperty("id")
          ) {
            return argValues.id;
          }

          return undefined;
        },
      },
      {
        kind: "linked",
        handle(field, record, argValues, store) {
          if (
            record != null &&
            record.__typename === ROOT_TYPE &&
            field.name === "teamScheduleEventItem" &&
            argValues.hasOwnProperty("id")
          ) {
            return argValues.id;
          }

          return undefined;
        },
      },
      {
        kind: "linked",
        handle(field, record, argValues, store) {
          if (
            record != null &&
            record.__typename === "TodoCategory" &&
            field.name === "team" &&
            argValues.hasOwnProperty("id")
          ) {
            return argValues.id;
          }

          return undefined;
        },
      },
      {
        kind: "linked",
        handle(field, record, argValues, store) {
          if (
            record != null &&
            record.__typename === "Store" &&
            field.name === "team" &&
            argValues.hasOwnProperty("id")
          ) {
            return argValues.id;
          }
          return undefined;
        },
      },

      {
        kind: "linked",
        handle(field, record, argValues, store) {
          if (
            record != null &&
            record.__typename === ROOT_TYPE &&
            field.name === "todo" &&
            argValues.hasOwnProperty("id")
          ) {
            return argValues.id;
          }
          return undefined;
        },
      },
      {
        kind: "linked",
        handle(field, record, argValues, store) {
          if (
            record != null &&
            record.__typename === ROOT_TYPE &&
            field.name === "issue" &&
            argValues.hasOwnProperty("id")
          ) {
            return argValues.id;
          }
          return undefined;
        },
      },
    ],
  });

  return {
    env,
    dispose: () => {
      subscriptionsClient.dispose();
    },
  };
}
