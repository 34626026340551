/**
 * @generated SignedSource<<a4f39c5b23c10a971a45187c960a4096>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Storecast_storecast$data = {
  readonly id: string;
  readonly name: string;
  readonly widgets: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"AllWidgets_widget" | "useWidgetToPos_widget">;
  }>;
  readonly " $fragmentType": "Storecast_storecast";
};
export type Storecast_storecast$key = {
  readonly " $data"?: Storecast_storecast$data;
  readonly " $fragmentSpreads": FragmentRefs<"Storecast_storecast">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Storecast_storecast",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "widgets",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useWidgetToPos_widget"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AllWidgets_widget"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Dashboard",
  "abstractKey": null
};
})();

(node as any).hash = "876d954e462a7b396a2bfe945ce9028d";

export default node;
