import { AHText, Box, useSpaceSize } from "arkh";

import { LogoBlack } from "../../OS/LogoBlack";
import React from "react";
import { View } from "react-native";
import { useScaleFactor } from "../../OS/UnitSizeProvider";
import { Clock } from "../Clock/clock";

export const DashboardHeader = ({
  title,
  screenName,
}: {
  title: string;
  screenName: string;
}) => {
  const space = useSpaceSize("widget");
  const sf = useScaleFactor();
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        paddingRight: space * 2,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <AHText type="title-big">{title}</AHText>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Clock />
        <View
          style={{
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <AHText type="title-small">{screenName}</AHText>
        </View>
        <Box style={{ justifyContent: "center" }} spaceLeft="large">
          <LogoBlack width={sf * 60} />
        </Box>
      </View>
    </View>
  );
};
