/**
 * @generated SignedSource<<f62463fbcd3b02a0471d20b25878547d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IssueImagePreloader_issues$data = ReadonlyArray<{
  readonly images: ReadonlyArray<{
    readonly id: string;
    readonly size: {
      readonly url: string;
    } | null;
  }> | null;
  readonly " $fragmentType": "IssueImagePreloader_issues";
}>;
export type IssueImagePreloader_issues$key = ReadonlyArray<{
  readonly " $data"?: IssueImagePreloader_issues$data;
  readonly " $fragmentSpreads": FragmentRefs<"IssueImagePreloader_issues">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "IssueImagePreloader_issues",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "minHeight",
              "value": 699
            },
            {
              "kind": "Literal",
              "name": "minWidth",
              "value": 699
            }
          ],
          "concreteType": "ImageSize",
          "kind": "LinkedField",
          "name": "size",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": "size(minHeight:699,minWidth:699)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Issue",
  "abstractKey": null
};

(node as any).hash = "97645d1de9cf9b644d9b52daeb04115e";

export default node;
