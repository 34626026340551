import { config } from "../../../config";
import { serializeJSON } from "./serializeJSON";

export type DeviceCodeSuccessResult = {
  deviceCode: string;
  userCode: string;
  verificationURI: string;
  verificationURIComplete: string;
  interval: number;
};

const domain = config.useNewAuth
  ? config.baseUrl + "/storecast"
  : config.domain;

export async function getDeviceCode(): Promise<DeviceCodeSuccessResult> {
  const res = await fetch(`${domain}/oauth/device/code`, {
    method: "post",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    body: serializeJSON({
      client_id: config.clientId,
      scope: "openid profile email offline_access",
      audience: config.audiance,
    }),
  });

  if (res.status !== 200) {
    throw new Error();
  }
  const response = await res.json();

  return {
    deviceCode: response.device_code,
    userCode: response.user_code,
    interval: response.interval * 1000,
    verificationURI: response.verification_uri,
    verificationURIComplete: response.verification_uri_complete,
  };
}

export async function getDeviceCodeMock(
  success: boolean,
  deviceCode = "dc"
): Promise<DeviceCodeSuccessResult> {
  if (!success) {
    throw new Error("random error");
  }
  return {
    deviceCode,
    userCode: "uc",
    interval: 5000,
    verificationURI: "uri",
    verificationURIComplete: "complete",
  };
}
