function getIsLocalIsh(serverUrl: string) {
  return (
    serverUrl.includes("localhost") ||
    serverUrl.includes("192") ||
    serverUrl.includes("10.0.") ||
    serverUrl.includes("127.0.")
  );
}

function serverUrls(baseUrl: string) {
  if (getIsLocalIsh(baseUrl)) {
    return {
      baseWSUrl: `ws://${process.env.SERVER_URL}:8080`,
      baseUrl: `http://${process.env.SERVER_URL}:8080`,
    };
  }
  return {
    baseWSUrl: `wss://${process.env.SERVER_URL}`,
    baseUrl: `https://${process.env.SERVER_URL}`,
  };
}

const prodConfig = {
  clientId: "CbIGTm2qmC7ucpNS473yYCq3Q6FYZXJ3",
  audiance: "https://server.storesprint.app",
  domain: "https://login.storesprint.app",
  baseWSUrl: "wss://api.storesprint.app",
  baseUrl: "https://api.storesprint.app",
  production: true,
  useNewAuth: true,
};

const betaConfig = {
  audiance: "https://beta-server.storesprint.app",
  clientId: "cwzHgXmX3KUXgfOnEk7A9d0kxGeJeENp",
  domain: "https://storesprint-beta.eu.auth0.com",
  ...serverUrls(
    process.env.SERVER_URL ? process.env.SERVER_URL : "beta.api.storesprint.app"
  ),

  production: false,
  useNewAuth: true,
};

const localConfig = {
  audiance: "https://beta-server.storesprint.app",
  clientId: "cwzHgXmX3KUXgfOnEk7A9d0kxGeJeENp",
  domain: "https://storesprint-beta.eu.auth0.com",
  baseWSUrl: "ws://localhost:8080",
  baseUrl: "http://localhost:8080",
  production: false,
  useNewAuth: true,
};

export const clientVersion = 2;
export const clientName = "TV";

export const configs = [
  {
    name: "Production",
    config: prodConfig,
  },
  {
    name: "Beta",
    config: betaConfig,
  },
  {
    name: "Local",
    config: localConfig,
  },
];

const getConfig = () => {
  if (process.env.NODE_ENV === "test") {
    return betaConfig;
  }
  switch (process.env.STORESPRINT_CONFIG) {
    case "beta":
      return betaConfig;
    case "prod":
      return prodConfig;
    case "local":
      return localConfig;
  }
  console.warn("NO CONFIG SET");
  return prodConfig;
};

export const config = getConfig();
