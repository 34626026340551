import { Platform } from "react-native";
import App from "./src/OS/App";
import { Browser } from "sentry-expo";
import { replay } from "./replay";
if (Platform.OS === "web") {
  Browser.init({
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,

    dsn: "https://c4e460c455df4e30b0f4eccd8aa9f87d@o123499.ingest.sentry.io/5259310",
    integrations: [replay],
  });
}

export default App;
