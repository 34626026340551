import { PersitedState } from "./PersitedState";

export function isValidLoggedInState(o: any): o is PersitedState {
  if (o?.refreshToken && o?.authToken && o?.idToken) {
    return (
      typeof o.refreshToken === "string" &&
      typeof o.authToken === "string" &&
      typeof o.idToken === "string"
    );
  }
  return false;
}
