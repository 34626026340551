/**
 * @generated SignedSource<<780447be1325e17bc5a1a18cc4c157e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IssueFocusDetailQuery$variables = {
  issueId: string;
};
export type IssueFocusDetailQuery$data = {
  readonly issue: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"IssueFocusDetailComponent_issue">;
  };
};
export type IssueFocusDetailQuery = {
  response: IssueFocusDetailQuery$data;
  variables: IssueFocusDetailQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "issueId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IssueFocusDetailQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Issue",
          "kind": "LinkedField",
          "name": "issue",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "IssueFocusDetailComponent_issue"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "issue"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IssueFocusDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Issue",
        "kind": "LinkedField",
        "name": "issue",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resolved",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "assignedTeams",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "taskExecution",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RoutineSectionVersion",
                "kind": "LinkedField",
                "name": "sectionVersion",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Routine",
                "kind": "LinkedField",
                "name": "routine",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Team",
                    "kind": "LinkedField",
                    "name": "team",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "minHeight",
                    "value": 699
                  },
                  {
                    "kind": "Literal",
                    "name": "minWidth",
                    "value": 699
                  }
                ],
                "concreteType": "ImageSize",
                "kind": "LinkedField",
                "name": "size",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": "size(minHeight:699,minWidth:699)"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f825247a5698319378bde29740e779cc",
    "id": null,
    "metadata": {},
    "name": "IssueFocusDetailQuery",
    "operationKind": "query",
    "text": "query IssueFocusDetailQuery(\n  $issueId: ID!\n) {\n  issue(id: $issueId) {\n    id\n    ...IssueFocusDetailComponent_issue\n  }\n}\n\nfragment IssueFocusDetailComponent_issue on Issue {\n  id\n  resolved\n  createdAt\n  title\n  assignedTeams {\n    id\n    name\n  }\n  taskExecution {\n    __typename\n    sectionVersion {\n      title\n      id\n    }\n    id\n  }\n  task {\n    __typename\n    title\n    routine {\n      title\n      team {\n        name\n        id\n      }\n      id\n    }\n    id\n  }\n  images {\n    size(minWidth: 699, minHeight: 699) {\n      url\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5399f81f7dbc69f8828210bac5d0aa53";

export default node;
