/**
 * @generated SignedSource<<fca42a8ea28e6ed3b731f906d9c960a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WidgetOverviewSize = "TWO_X_FOUR" | "TWO_X_ONE" | "TWO_X_THREE" | "TWO_X_TWO";
import { FragmentRefs } from "relay-runtime";
export type TeamScheduleFocusScreen_widgetTeamSchedule$data = {
  readonly content: {
    readonly __typename: "WidgetTeamScheduleContentDaily";
    readonly column: {
      readonly events: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"useNowIndexes_teamScheduleEventOccasion">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"TeamScheduleFocusScreenColumn_teamSchedule">;
    };
  } | {
    readonly __typename: "WidgetTeamScheduleContentMonthly";
    readonly column: {
      readonly " $fragmentSpreads": FragmentRefs<"TeamScheduleFocusScreenColumn_teamSchedule">;
    };
  } | {
    readonly __typename: "WidgetTeamScheduleContentSummary";
    readonly dailyTeamSchedule: {
      readonly events: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"useNowIndexes_teamScheduleEventOccasion">;
      }>;
    };
    readonly monthlyTeamSchedule: {
      readonly events: ReadonlyArray<{
        readonly id: string;
      }>;
    };
    readonly weeklyTeamSchedule: {
      readonly events: ReadonlyArray<{
        readonly id: string;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"TeamScheduleFocusScreenSummary_contentSummary">;
  } | {
    readonly __typename: "WidgetTeamScheduleContentWeekly";
    readonly column: {
      readonly " $fragmentSpreads": FragmentRefs<"TeamScheduleFocusScreenColumn_teamSchedule">;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly id: string;
  readonly overviewSize: WidgetOverviewSize;
  readonly teams: ReadonlyArray<{
    readonly color: string | null;
    readonly id: string;
  }>;
  readonly " $fragmentType": "TeamScheduleFocusScreen_widgetTeamSchedule";
};
export type TeamScheduleFocusScreen_widgetTeamSchedule$key = {
  readonly " $data"?: TeamScheduleFocusScreen_widgetTeamSchedule$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamScheduleFocusScreen_widgetTeamSchedule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "includeInlineRoutines",
    "value": true
  }
],
v2 = {
  "alias": null,
  "args": (v1/*: any*/),
  "concreteType": null,
  "kind": "LinkedField",
  "name": "events",
  "plural": true,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useNowIndexes_teamScheduleEventOccasion"
    }
  ],
  "storageKey": "events(includeInlineRoutines:true)"
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "TeamScheduleFocusScreenColumn_teamSchedule"
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": null,
    "kind": "LinkedField",
    "name": "events",
    "plural": true,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": "events(includeInlineRoutines:true)"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamScheduleFocusScreen_widgetTeamSchedule",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Team",
      "kind": "LinkedField",
      "name": "teams",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overviewSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "column",
              "args": null,
              "concreteType": "TeamSchedule",
              "kind": "LinkedField",
              "name": "dailyTeamSchedule",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "WidgetTeamScheduleContentDaily",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "column",
              "args": null,
              "concreteType": "TeamSchedule",
              "kind": "LinkedField",
              "name": "weeklyTeamSchedule",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "type": "WidgetTeamScheduleContentWeekly",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "column",
              "args": null,
              "concreteType": "TeamSchedule",
              "kind": "LinkedField",
              "name": "monthlyTeamSchedule",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "type": "WidgetTeamScheduleContentMonthly",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamSchedule",
              "kind": "LinkedField",
              "name": "dailyTeamSchedule",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamSchedule",
              "kind": "LinkedField",
              "name": "monthlyTeamSchedule",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamSchedule",
              "kind": "LinkedField",
              "name": "weeklyTeamSchedule",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TeamScheduleFocusScreenSummary_contentSummary"
            }
          ],
          "type": "WidgetTeamScheduleContentSummary",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WidgetTeamSchedule",
  "abstractKey": null
};
})();

(node as any).hash = "6ef4508dcf57ed4140dea583b937bb3a";

export default node;
