/**
 * @generated SignedSource<<1a221008e9bea3870999e813656d7cac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IssueFocusDetailSubscription$variables = {
  issueId: string;
};
export type IssueFocusDetailSubscription$data = {
  readonly issuesChangedByIds: {
    readonly " $fragmentSpreads": FragmentRefs<"IssueFocusDetailComponent_issue">;
  } | null;
};
export type IssueFocusDetailSubscription = {
  response: IssueFocusDetailSubscription$data;
  variables: IssueFocusDetailSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueId"
  }
],
v1 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "issueIds.0",
        "variableName": "issueId"
      }
    ],
    "kind": "ListValue",
    "name": "issueIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IssueFocusDetailSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Issue",
        "kind": "LinkedField",
        "name": "issuesChangedByIds",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "IssueFocusDetailComponent_issue"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IssueFocusDetailSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Issue",
        "kind": "LinkedField",
        "name": "issuesChangedByIds",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resolved",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "assignedTeams",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "taskExecution",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RoutineSectionVersion",
                "kind": "LinkedField",
                "name": "sectionVersion",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Routine",
                "kind": "LinkedField",
                "name": "routine",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Team",
                    "kind": "LinkedField",
                    "name": "team",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "minHeight",
                    "value": 699
                  },
                  {
                    "kind": "Literal",
                    "name": "minWidth",
                    "value": 699
                  }
                ],
                "concreteType": "ImageSize",
                "kind": "LinkedField",
                "name": "size",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": "size(minHeight:699,minWidth:699)"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dceb901f27c877ee719bd418cf7b3ddd",
    "id": null,
    "metadata": {},
    "name": "IssueFocusDetailSubscription",
    "operationKind": "subscription",
    "text": "subscription IssueFocusDetailSubscription(\n  $issueId: ID!\n) {\n  issuesChangedByIds(issueIds: [$issueId]) {\n    ...IssueFocusDetailComponent_issue\n    id\n  }\n}\n\nfragment IssueFocusDetailComponent_issue on Issue {\n  id\n  resolved\n  createdAt\n  title\n  assignedTeams {\n    id\n    name\n  }\n  taskExecution {\n    __typename\n    sectionVersion {\n      title\n      id\n    }\n    id\n  }\n  task {\n    __typename\n    title\n    routine {\n      title\n      team {\n        name\n        id\n      }\n      id\n    }\n    id\n  }\n  images {\n    size(minWidth: 699, minHeight: 699) {\n      url\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "37172414adb183a2ca9497b7f9d96be6";

export default node;
