import { AHText, Box, useScaleFactor, useTheme } from "arkh";

import { LogoWhite } from "./LogoWhite";
import QRCode from "react-native-qrcode-svg";
import React from "react";
import { View } from "react-native";
import { FormattedMessage } from "react-intl";

const Instructionitem = (props: { text: React.ReactNode; num: string }) => {
  const sf = useScaleFactor();
  return (
    <Box
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: 50 * sf,
      }}
    >
      <Box
        style={{
          height: 60 * sf,
          width: 60 * sf,
          borderRadius: 30 * sf,
          marginRight: 20 * sf,
          backgroundColor: "#0066ff",
          alignItems: "center",
          justifyContent: "center",
        }}
        color="primary"
      >
        <AHText
          style={{
            textAlign: "center",
            fontSize: 40 * sf,
            color: "white",
            fontFamily: "poppins-display",
          }}
          color="lightText"
          type="title-big"
        >
          {props.num}
        </AHText>
      </Box>
      <AHText
        style={{
          textAlign: "left",
          fontSize: 20 * sf,
          fontFamily: "poppins-display",
        }}
        type="title-big"
      >
        {props.text}
      </AHText>
    </Box>
  );
};

export function PollForTokenScreen(props: { userCode: string }) {
  const theme = useTheme();
  const sf = useScaleFactor();

  return (
    <View
      style={{
        flex: 1,
        overflow: "hidden",
        position: "relative",
        height: "100%",
      }}
    >
      <View
        style={{
          position: "absolute",
          backgroundColor: "#F1F1F1",
          opacity: 0.7,
          height: "100%",
          width: "100%",
          flex: 1,
        }}
      >
        <picture
          className="MovePerson"
          style={{
            height: "100%",
            width: "auto",
            position: "absolute",
            left: 100 * sf,
          }}
        >
          <source
            type="image/webp"
            srcSet={require("../assets/images/QR/p1.webp")}
          />
          <img src={require("../assets/images/QR/p1.png")} />
        </picture>

        <picture
          className="MoveForeground"
          style={{
            height: "70%",
            width: "auto",
            position: "absolute",
            bottom: 0,
          }}
        >
          <source
            type="image/webp"
            srcSet={require("../assets/images/QR/p2.webp")}
          />
          <img src={require("../assets/images/QR/p2.png")} />
        </picture>

        <div
          style={{
            height: "100%",
            position: "absolute",
            alignItems: "flex-end",
            right: 0,
            bottom: 0,
            display: "flex",
          }}
        >
          <picture className="MoveForeground">
            <source
              type="image/webp"
              srcSet={require("../assets/images/QR/p3.webp")}
            />
            <img src={require("../assets/images/QR/p3.png")} />
          </picture>
        </div>
      </View>
      <View
        style={{
          alignItems: "stretch",
          justifyContent: "center",
          flexDirection: "row",
          flex: 1,
          paddingHorizontal: 100 * sf,
        }}
      >
        <View
          style={{
            flex: 2,
            alignItems: "flex-start",
            justifyContent: "space-around",
          }}
        >
          <View>
            <LogoWhite style={{ height: 150 * sf, width: 150 * sf }} />
          </View>
          <View>
            <AHText
              style={{
                fontSize: 160 * sf,
                fontFamily: "poppins-display",
              }}
              type="title-big"
            >
              Storecast
            </AHText>
            <AHText
              style={{
                textAlign: "center",
                fontSize: 35 * sf,
                fontFamily: "poppins-display",
              }}
              type="title-big"
            >
              <FormattedMessage
                description="Login QR splash screen subtitle"
                defaultMessage="Your Digital Operations Dashboard, by StoreSprint"
              />
            </AHText>
          </View>
          <View
            style={{
              borderRadius: 20 * sf,
              backgroundColor: "white",
              padding: 30 * sf,
            }}
          >
            <View style={{ paddingBottom: 10 * sf }}>
              <AHText style={{ textAlign: "center", fontSize: 20 * sf }}>
                <FormattedMessage
                  description="Login QR splash screen support info"
                  defaultMessage="Contact customer support:"
                />
              </AHText>
            </View>
            <AHText
              style={{ textAlign: "center", fontSize: 30 * sf }}
              type="title-big"
            >
              ‭010 - 147 90 11‬
            </AHText>
          </View>
        </View>
        <View
          style={{
            alignItems: "stretch",
            flex: 1,
            flexDirection: "row",
            paddingTop: 40 * sf,
          }}
        >
          <Box
            style={{
              backgroundColor: "rgba(255,255,255,0.5)",
              flex: 1,
              alignItems: "stretch",
              paddingHorizontal: 50 * sf,
              borderTopLeftRadius: 50 * sf,
              borderTopRightRadius: 50 * sf,
              paddingVertical: 100 * sf,
            }}
          >
            <Instructionitem
              num="1"
              text={
                <FormattedMessage
                  description="Login QR splash screen instruction item 1"
                  defaultMessage="Download StoreSprint from App Store or Google Play"
                />
              }
            />
            <Instructionitem
              num="2"
              text={
                <FormattedMessage
                  description="Login QR splash screen instruction item 2"
                  defaultMessage="Open StoreSprint with your phone or tablet"
                />
              }
            />
            <Instructionitem
              num="3"
              text={
                <FormattedMessage
                  description="Login QR splash screen instruction item 3"
                  defaultMessage="Click on Store Admin > Screens > Add Screen"
                />
              }
            />

            <Box
              style={{
                alignSelf: "center",
                alignItems: "center",
                paddingTop: 100 * sf,
              }}
            >
              <QRCode
                size={400 * sf}
                color={theme.darkerText}
                backgroundColor="transparent"
                ecl="H"
                value={"storeapp://storecast/add/" + props.userCode}
              />
            </Box>
          </Box>
        </View>
      </View>
    </View>
  );
}
