import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export function LogoWhite(props: SvgProps) {
  return (
    <Svg viewBox="0 0 188 110" fill="none" {...props}>
      <Path
        d="M37.94 55.48c6.974-.515 13.946-1.08 20.914-1.697l5.229-.455 5.229-.48c3.482-.319 6.967-.688 10.452-1.106a6.724 6.724 0 014.96 1.412 6.766 6.766 0 011.102 9.487 6.732 6.732 0 01-4.503 2.518 7.364 7.364 0 01-1.559 0 444.18 444.18 0 00-10.462-1.127l-5.229-.479-5.228-.455a1449.622 1449.622 0 00-20.915-1.696 2.95 2.95 0 01-1.955-.934 2.965 2.965 0 010-4.04 2.95 2.95 0 011.955-.934l.01-.015zM61.837 78.901a40.384 40.384 0 013.049-.862 87.05 87.05 0 013.05-.69c.506-.115 1.012-.202 1.524-.297.511-.096 1.013-.197 1.525-.269a9.83 9.83 0 013.044 0 4.595 4.595 0 012.726 1.576 4.617 4.617 0 010 5.929 4.595 4.595 0 01-2.726 1.576 9.98 9.98 0 01-3.044 0c-.512-.067-1.019-.173-1.525-.269-.507-.095-1.018-.182-1.525-.297a69.71 69.71 0 01-3.05-.685 42.712 42.712 0 01-3.048-.867 2.529 2.529 0 01-1.703-2.396 2.54 2.54 0 011.703-2.396v-.053zM13.86 32.79c4.346-.137 8.688-.332 13.024-.584 4.337-.253 8.677-.53 13.02-.834 4.34-.307 8.68-.633 13.019-1.045 4.34-.412 8.68-.877 13.02-1.476a6.739 6.739 0 015.025 1.298 6.765 6.765 0 012.623 4.49 6.788 6.788 0 01-1.328 5.03 6.76 6.76 0 01-4.495 2.6 6.847 6.847 0 01-1.826 0 343.42 343.42 0 00-13.02-1.472c-4.339-.412-8.679-.738-13.018-1.025-4.34-.288-8.68-.59-13.02-.839s-8.674-.46-13.024-.599a2.764 2.764 0 01-1.882-.851 2.778 2.778 0 010-3.841 2.764 2.764 0 011.882-.852zM146.634 98.097c-19.171 3.738-34.991-3.943-40.305-17.178a2.877 2.877 0 012.122-3.896l15.547-3.028a2.863 2.863 0 013.069 1.466c2.643 4.95 8.799 7.667 16.25 6.23 8.192-1.597 11.948-6.201 10.993-10.979-2.868-14.782-47.661 3.71-53.53-26.604-2.662-13.738 7.408-27.17 27.811-31.146 18.583-3.623 32.157 3.536 37.471 17.614a2.877 2.877 0 01-2.131 3.834l-13.297 2.592a4.372 4.372 0 01-4.65-2.022c-2.6-4.495-7.858-6.665-14.783-5.319-7.748 1.51-11.16 5.43-10.233 10.211 2.925 15.08 46.839-3.239 53.301 26.959 2.753 14.183-7.528 27.347-27.635 31.266z"
        fill="#FFF"
      />
    </Svg>
  );
}
