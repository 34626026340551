/**
 * @generated SignedSource<<95ebaf11ecd7393cbd74b3ed5e31ed09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FileFocusScreen_file$data = {
  readonly id: string;
  readonly pages: ReadonlyArray<{
    readonly id: string;
    readonly image: {
      readonly size: {
        readonly url: string;
      } | null;
    } | null;
  }>;
  readonly " $fragmentType": "FileFocusScreen_file";
};
export type FileFocusScreen_file$key = {
  readonly " $data"?: FileFocusScreen_file$data;
  readonly " $fragmentSpreads": FragmentRefs<"FileFocusScreen_file">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FileFocusScreen_file",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FilePage",
      "kind": "LinkedField",
      "name": "pages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageSize",
              "kind": "LinkedField",
              "name": "size",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "File",
  "abstractKey": null
};
})();

(node as any).hash = "e81fd65302a004ec8cd703891523ee48";

export default node;
