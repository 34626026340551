import React from "react";
import { graphql, useFragment } from "react-relay";
import { IssueImagePreloader_issues$key } from "./__generated__/IssueImagePreloader_issues.graphql";

export const ImagePreloader = React.memo(ImagePreloaderC);
function ImagePreloaderC(props: { issues: IssueImagePreloader_issues$key }) {
  const issues = useFragment(
    graphql`
      fragment IssueImagePreloader_issues on Issue @relay(plural: true) {
        images {
          id
          size(minWidth: 699, minHeight: 699) {
            url
          }
        }
      }
    `,
    props.issues
  );

  return (
    <>
      {issues
        .flatMap((issue) =>
          issue.images?.map((image) =>
            image.size ? (
              <link
                key={image.id}
                href={image.size?.url}
                rel="preload"
                as="image"
              />
            ) : null
          )
        )
        .slice(0, 15)}
    </>
  );
}
