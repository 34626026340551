import { useEffect, useState } from "react";

const regex = new RegExp(/loc=([\w]{2})/);
export function useGuessLocale() {
  // guess locale based on country of ip
  const [guessLocale, setGuessLocale] = useState<"sv" | "en">("sv");
  useEffect(() => {
    fetch("https://www.cloudflare.com/cdn-cgi/trace")
      .then((r) => {
        return r.text();
      })
      .then((r) => {
        const [resp] = regex.exec(r);
        if (resp && resp.replace("loc=", "").toLowerCase() !== "se") {
          setGuessLocale("en");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return guessLocale;
}
