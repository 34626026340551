/**
 * @generated SignedSource<<27c3912bb048a99e740d5acf9396b148>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type TeamScheduleFocusScreenSrolledSubscription$variables = {
  widgetId: string;
};
export type TeamScheduleFocusScreenSrolledSubscription$data = {
  readonly widgetFocusScrollChanged: string;
};
export type TeamScheduleFocusScreenSrolledSubscription = {
  response: TeamScheduleFocusScreenSrolledSubscription$data;
  variables: TeamScheduleFocusScreenSrolledSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "widgetId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "widgetId",
        "variableName": "widgetId"
      }
    ],
    "kind": "ScalarField",
    "name": "widgetFocusScrollChanged",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamScheduleFocusScreenSrolledSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeamScheduleFocusScreenSrolledSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b259675bb7d49c6f5fd1d73ce4582c20",
    "id": null,
    "metadata": {},
    "name": "TeamScheduleFocusScreenSrolledSubscription",
    "operationKind": "subscription",
    "text": "subscription TeamScheduleFocusScreenSrolledSubscription(\n  $widgetId: ID!\n) {\n  widgetFocusScrollChanged(widgetId: $widgetId)\n}\n"
  }
};
})();

(node as any).hash = "174607b732be708358875364ea802dd3";

export default node;
