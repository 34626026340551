import {
  AHQueryHandlerLoading,
  Box,
  ScaleFactorProvider,
  useScaleFactor,
} from "arkh";
import { StyleSheet } from "react-native";
import React, {
  Suspense,
  useMemo,
  useRef,
  useState,
  useEffect,
  useDeferredValue,
} from "react";
import {
  WidgetIssueOverviewList,
  WidgetIssueOverviewRef,
} from "widgets/src/WidgetIssueOverview/WidgetIssueOverviewList";
import { graphql, useFragment } from "react-relay";
import { IssueFocusScreen_widgetIssueOverview$key } from "./__generated__/IssueFocusScreen_widgetIssueOverview.graphql";
import { IssueFocusDetail } from "./IssueFocusDetail";

import { useSubscriptionWithSkip } from "widgets/src/utils/useSubscriptionWithSkip";
import { IssueFocusScreenSrolledSubscription } from "./__generated__/IssueFocusScreenSrolledSubscription.graphql";
import { ImagePreloader } from "./IssueImagePreloader";

function noOp() {}
export function IssueFocusScreen(props: {
  widget: IssueFocusScreen_widgetIssueOverview$key;
  issueId?: string;
  onSelectDetailItem: (id: string) => void;
}) {
  const sf = useScaleFactor();

  const widget = useFragment(
    graphql`
      fragment IssueFocusScreen_widgetIssueOverview on WidgetIssueOverview {
        id
        issues {
          id
          ...WidgetIssueOverviewList_issue
          ...IssueFocusDetailComponent_issue
          ...IssueImagePreloader_issues
        }
      }
    `,
    props.widget
  );
  const issueOverviewRef = useRef<WidgetIssueOverviewRef>(null);

  const defferedId = useDeferredValue(props.issueId);

  useSubscriptionWithSkip<IssueFocusScreenSrolledSubscription>(
    useMemo(
      () => ({
        subscription: graphql`
          subscription IssueFocusScreenSrolledSubscription($widgetId: ID!) {
            widgetFocusScrollChanged(widgetId: $widgetId)
          }
        `,
        variables: { widgetId: widget.id },
        onNext: (data) => {
          issueOverviewRef.current?.scrollToItem(
            data?.widgetFocusScrollChanged ?? null
          );
        },
      }),
      [widget.id]
    )
  );

  return (
    <>
      <Box
        space="xxlarge"
        style={{
          flex: 1,
          width: props.issueId ? "80%" : "50%",
          alignSelf: "center",
        }}
      >
        <Box
          style={{
            flex: 1,
            flexDirection: "row",
            overflow: "hidden",
          }}
          cornerRadius="medium"
          color="surface"
        >
          <Box style={{ flexBasis: props.issueId ? "30%" : "100%" }}>
            <WidgetIssueOverviewList
              ref={issueOverviewRef}
              issues={widget.issues}
              isFocused
              mode="FOCUS"
              onPressItem={props.onSelectDetailItem}
              setScroll={noOp}
              interactive
              highlightedItemId={defferedId}
            />
          </Box>
          {props.issueId ? (
            <Box
              style={{
                flexBasis: "70%",
                borderLeftWidth: StyleSheet.hairlineWidth,
              }}
              borderColor="surfaceBorder"
            >
              <ScaleFactorProvider value={sf * 1.5}>
                <Suspense fallback={<AHQueryHandlerLoading />}>
                  <IssueFocusDetail issueId={props.issueId} />
                </Suspense>
              </ScaleFactorProvider>
            </Box>
          ) : null}
        </Box>
      </Box>
      <ImagePreloader issues={widget.issues} />
    </>
  );
}
