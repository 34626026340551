import { config } from "../../../config";
import decodeJWT from "jwt-decode";
import { serializeJSON } from "./serializeJSON";

export const client_id = config.clientId;

export const domain = config.useNewAuth
  ? config.baseUrl + "/storecast"
  : config.domain;

export type CheckForTokenSuccess = {
  success: true;
  authToken: string;
  refreshToken?: string;
  idToken: string;
};

export type CheckForTokenError =
  | {
      success: false;
      error: "authorization_pending";
      error_description: string;
    }
  | {
      success: false;
      error: "slow_down";
      error_description: string;
    }
  | {
      success: false;
      error: "expired_token";
      error_description: string;
    }
  | {
      success: false;
      error: "access_denied";
      error_description: string;
    }
  | { success: false; error: "network_error" };

export async function checkForToken(
  deviceCode: string
): Promise<CheckForTokenError | CheckForTokenSuccess> {
  const res = await fetch(`${domain}/oauth/token`, {
    method: "post",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    body: serializeJSON({
      client_id,
      grant_type: "urn:ietf:params:oauth:grant-type:device_code",
      device_code: deviceCode,
    }),
  }).catch(() => null);

  if (res === null) {
    return { success: false, error: "network_error" };
  }
  const success = res.status === 200;
  return res.json().then((k) => {
    if (success) {
      const token = decodeJWT(k.access_token) as any;
      const currentStoreId =
        typeof token === "object" && token
          ? token["https://server.storesprint.app/store_id"]
          : undefined;

      return {
        success: true,
        currentStoreId,
        authToken: k.access_token,
        idToken: k.id_token,
        refreshToken: k.refresh_token,
      };
    } else {
      return { success: false, ...k };
    }
  });
}

export async function checkForTokenMockError(
  error: CheckForTokenError["error"]
): Promise<CheckForTokenError> {
  return { success: false, error, error_description: "" };
}
export async function checkForTokenMockSuccess(
  authToken: string,
  idToken: string,
  refreshToken: string
): Promise<CheckForTokenSuccess> {
  return { success: true, authToken, idToken, refreshToken };
}
