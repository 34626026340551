import { AHText, Box, useSpaceSize } from "arkh";
import React, { useMemo, useRef, useState } from "react";
import { View, useWindowDimensions } from "react-native";
import { AllWidgets, WidgetLayoutProvider, useWidgetsToPos } from "widgets";
import { DashboardHeader } from "./StorecastHeader";
import { useUnitSize } from "../../OS/UnitSizeProvider";
import { useFragment, graphql, useSubscription } from "react-relay";
import type { Storecast_storecast$key } from "./__generated__/Storecast_storecast.graphql";
import type { StorecastChangedTVSubscription } from "./__generated__/StorecastChangedTVSubscription.graphql";
import type { StorecastLayoutChangedTVSubscription } from "./__generated__/StorecastLayoutChangedTVSubscription.graphql";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "sentry-expo";
import { AllWidgets_widget$key } from "widgets/src/__generated__/AllWidgets_widget.graphql";

const StorecastComponentC = (props: {
  storecast: Storecast_storecast$key;
  screenName: string;
}) => {
  const { width } = useWindowDimensions();
  const storecast = useFragment(
    graphql`
      fragment Storecast_storecast on Dashboard {
        id
        name
        widgets {
          id
          ...useWidgetToPos_widget
          ...AllWidgets_widget
        }
      }
    `,
    props.storecast
  );

  const pixelShift = useRef(Math.round(Math.random() * 9));

  const unitSize = Math.floor(useUnitSize());
  const paddingHorizontal = Math.round((width - unitSize * 8) / 4);
  const margin = useSpaceSize("medium");
  const storecastId = storecast.id;
  const storecastName = storecast.name;
  const screenName = props.screenName;
  useSubscription<StorecastLayoutChangedTVSubscription>(
    useMemo(
      () => ({
        variables: { storecastId },
        subscription: graphql`
          subscription StorecastLayoutChangedTVSubscription($storecastId: ID!) {
            widgetsRearranged(storecastId: $storecastId) {
              id
              widgets {
                ...useWidgetToPos_widget
              }
            }
          }
        `,
      }),
      [storecastId]
    )
  );
  useSubscription<StorecastChangedTVSubscription>(
    useMemo(
      () => ({
        updater: (store, data) => {
          data.storecastChanged.widgets
            .filter(
              (w) =>
                w.__typename === "WidgetTodoOverview" ||
                w.__typename === "WidgetIssueOverview"
            )
            .forEach((w) => {
              store.get(w.id)?.invalidateRecord();
            });
        },
        variables: { storecastId },
        subscription: graphql`
          subscription StorecastChangedTVSubscription($storecastId: ID!) {
            storecastChanged(storecastId: $storecastId) {
              id
              name
              widgets {
                id
                __typename
                ...useWidgetToPos_widget
                ...AllWidgets_widget
              }
            }
          }
        `,
      }),
      [storecastId]
    )
  );

  const positions = useWidgetsToPos(storecast.widgets);
  return (
    <View
      style={{
        transform: [{ translateY: -Math.abs(pixelShift.current) }],
        flex: 1,
        paddingHorizontal,
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            flex: 2,
            width: unitSize * 8,
            alignContent: "center",
            justifyContent: "center",
          }}
          spaceHorizontal="xxsmall"
        >
          <DashboardHeader
            title={storecastName ?? ""}
            screenName={screenName ?? ""}
          />
        </Box>
        <View
          style={{
            width: unitSize * 8,
            height: unitSize * 4,
            position: "relative",
          }}
        >
          {positions.map(({ w, h, positionX, y, id }) => {
            const widget = storecast.widgets.find((w) => w.id === id);
            if (!widget) {
              return null;
            }
            return (
              <WidgetLayoutProvider
                key={id}
                value={{
                  width: unitSize * w - margin,
                  height: unitSize * h - margin,
                  unitSize,
                  h,
                }}
              >
                <Box
                  style={[
                    { transition: "transform .3s" } as any,
                    {
                      position: "absolute",
                      transform: [
                        { translateY: y * unitSize },
                        { translateX: positionX * unitSize },
                      ],
                      width: w * unitSize - margin,
                      height: h * unitSize - margin,
                    },
                  ]}
                >
                  <Box
                    style={{
                      height: "100%",
                      width: "100%",
                      overflow: "hidden",
                    }}
                    color="surface"
                    cornerRadius="medium"
                  >
                    <WidgetWithErrorBoundrary
                      widget={widget}
                      storecastId={storecastId}
                    />
                  </Box>
                </Box>
              </WidgetLayoutProvider>
            );
          })}
        </View>
        <Box
          spaceHorizontal="widget"
          style={{
            flex: 1,
            width: "100%",
          }}
        />
      </View>
    </View>
  );
};

function WidgetWithErrorBoundrary(props: {
  widget: AllWidgets_widget$key;
  storecastId: string;
}) {
  const [fk, setFk] = useState(0);

  return (
    <ErrorBoundary
      onError={(err) => {
        console.log(err);
        Sentry.Browser.captureException(err);
        setFk((k) => (k > 5 ? k : k + 1));
      }}
      fallbackRender={(err) => {
        return (
          <Box
            spaceHorizontal="medium"
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AHText spaceBottom="medium" type="title-small">
              Error
            </AHText>
          </Box>
        );
      }}
    >
      <AllWidgets
        idiom="STATIC"
        fetchKey={fk + ""}
        widget={props.widget}
        mode="IDLE"
      />
    </ErrorBoundary>
  );
}
export const Storecast = React.memo(StorecastComponentC);
