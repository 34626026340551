import React from "react";
import { View } from "react-native";
import { AHText } from "arkh";
import { FormattedMessage } from "react-intl";
export const NoAssignedDashboard = (props: { screenName?: string }) => {
  return (
    <View style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <AHText spaceBottom="large" type="title-big">
          <FormattedMessage
            description="No assigned dashboard title"
            defaultMessage="No assigned Dashboard"
          />
        </AHText>
        {props.screenName ? (
          <AHText>
            <FormattedMessage
              description="No assigned Dashboard body"
              defaultMessage="Assign a dashboard to the screen {screenName}"
              values={{
                screenName: props.screenName,
              }}
            />
          </AHText>
        ) : null}
      </View>
    </View>
  );
};
