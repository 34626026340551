import * as Sentry from "@sentry/browser";

import { LOCAL_STORAGE_KEY } from "./LOCAL_STORAGE_KEY";
import { PersitedState } from "./PersitedState";
import { isValidLoggedInState } from "./isValidLoggedInState";

const getLocalState = (): PersitedState | undefined => {
  const str = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (str) {
    const val = JSON.parse(str);
    if (isValidLoggedInState(val)) {
      return val;
    }
  }
  return undefined;
};

export const retriveLocalStorage = async () => {
  Sentry.addBreadcrumb({
    type: "localstorage",
    message: "retriveLocalStorage",
  });
  const state = getLocalState();
  if (!state) {
    throw new Error("No old state");
  }

  Sentry.setExtra("lastPersisted", state.lastPersistedAt);

  return state;
};

export const retriveLocalStorageMock = async (
  success: boolean
): Promise<PersitedState> => {
  if (success) {
    return {
      authToken: "at",

      idToken: "it",
      refreshToken: "rt",
    };
  }
  throw new Error("Random error");
};
