import { AHTag, AHText, Box } from "arkh";
import React, { useMemo, Suspense } from "react";
import { DateTime } from "luxon";
import { MasonryImageGrid } from "../../../common/MasonryImageGrid";
import { TodoCircle } from "widgets/src/WidgetTodoOverview/TodoCircle";
import { InfoElement } from "../InfoElement";
import { useFragment, graphql, useLazyLoadQuery } from "react-relay";

import { TodoDetailImages_todo$key } from "./__generated__/TodoDetailImages_todo.graphql";
import { TodoDetailQuery } from "./__generated__/TodoDetailQuery.graphql";
import { TodoDetailInfoItems_todo$key } from "./__generated__/TodoDetailInfoItems_todo.graphql";
import { TodoDetailTeams_todo$key } from "./__generated__/TodoDetailTeams_todo.graphql";
import { TodoDetailBodyAndTags_todo$key } from "./__generated__/TodoDetailBodyAndTags_todo.graphql";
import { useSubscriptionWithSkip } from "widgets/src/utils/useSubscriptionWithSkip";
import { TodoDetailSubscription } from "./__generated__/TodoDetailSubscription.graphql";
import { FormattedMessage } from "react-intl";

function noOp() {}
export function TodoDetail(props: { todoId: string }) {
  const { todo } = useLazyLoadQuery<TodoDetailQuery>(
    graphql`
      query TodoDetailQuery($todoId: ID!) {
        todo(id: $todoId) @required(action: THROW) {
          id
          title
          ...TodoDetailBodyAndTags_todo
          ...TodoDetailTeams_todo
          ...TodoCircleWidgetOverview_Todo
          ...TodoDetailInfoItems_todo
          ...TodoDetailImages_todo
        }
      }
    `,
    {
      todoId: props.todoId,
    },
    {
      fetchPolicy: "store-and-network",
    }
  );

  useSubscriptionWithSkip<TodoDetailSubscription>(
    useMemo(
      () => ({
        variables: { todoId: props.todoId },
        subscription: graphql`
          subscription TodoDetailSubscription($todoId: ID!) {
            todoChangedByIds(todoIds: [$todoId]) {
              id
              title
              ...TodoDetailBodyAndTags_todo
              ...TodoDetailTeams_todo
              ...TodoCircleWidgetOverview_Todo
              ...TodoDetailInfoItems_todo
              ...TodoDetailImages_todo
            }
          }
        `,
      }),
      [props.todoId]
    )
  );

  return (
    <Box
      style={{ flex: 1 }}
      color="surface"
      spaceHorizontal="medium"
      spaceVertical="small"
    >
      <Suspense
        fallback={
          <AHText type="detail" style={{ textAlign: "right" }}>
            {" "}
          </AHText>
        }
      >
        <TodoTeams todo={todo} />
      </Suspense>
      <Box style={{ flexDirection: "row", alignItems: "center" }}>
        <TodoCircle todo={todo} onPress={noOp} />
        <AHText spaceLeft="small" style={{ flex: 1 }} type="title-big">
          {todo.title}
        </AHText>
      </Box>
      <Suspense fallback={null}>
        <TodoBodyAndTags todo={todo} />
        <TodoInfoItems todo={todo} />
        <TodoDetailImages todo={todo} />
      </Suspense>
    </Box>
  );
}

function TodoDetailImages(props: { todo: TodoDetailImages_todo$key }) {
  const todo = useFragment(
    graphql`
      fragment TodoDetailImages_todo on Todo {
        images {
          size(minHeight: 699, minWidth: 699) {
            url
          }
        }
      }
    `,
    props.todo
  );

  const images = useMemo(
    () =>
      todo.images.flatMap((image) =>
        image.size?.url ? [{ url: image.size.url }] : []
      ),
    [todo.images]
  );

  return <MasonryImageGrid images={images} />;
}

function TodoInfoItems(props: { todo: TodoDetailInfoItems_todo$key }) {
  const todo = useFragment(
    graphql`
      fragment TodoDetailInfoItems_todo on Todo {
        createdBy {
          name
        }
        assigneeText
        assignedUsers {
          user {
            name
          }
        }
        expireAt
      }
    `,
    props.todo
  );

  const assigneeText = useMemo(
    () =>
      todo.assignedUsers
        .map(({ user }) => {
          const name = user?.name;
          if (!name) {
            return "";
          }
          const names = name.split(" ");
          return `${names[0]}${
            names.length > 1 ? " " + names[1].charAt(0) : ""
          }`;
        })
        .join(" · ") +
      (todo.assigneeText !== "" ? `·  ${todo.assigneeText}` : ""),
    [todo.assignedUsers, todo.assigneeText]
  );

  return (
    <Box spaceVertical="small">
      {todo.createdBy?.name ? (
        <InfoElement
          iconType="user"
          title={
            <FormattedMessage
              description="TodoFocus created by"
              defaultMessage="Created By"
            />
          }
          content={todo.createdBy.name}
        />
      ) : null}
      {assigneeText.length > 0 ? (
        <InfoElement
          iconType="users"
          title={
            <FormattedMessage
              description="TodoFocus assigned users"
              defaultMessage="Assigned Users"
            />
          }
          content={assigneeText}
        />
      ) : null}
      {todo.expireAt ? (
        <InfoElement
          iconType="deadline"
          title="Deadline"
          content={DateTime.fromISO(todo.expireAt).toLocaleString(
            DateTime.DATETIME_MED_WITH_WEEKDAY
          )}
        />
      ) : null}
    </Box>
  );
}

function TodoTeams(props: { todo: TodoDetailTeams_todo$key }) {
  const todo = useFragment(
    graphql`
      fragment TodoDetailTeams_todo on Todo {
        teams {
          name
        }
      }
    `,
    props.todo
  );

  return (
    <AHText type="detail" style={{ textAlign: "right" }}>
      {todo.teams.map(({ name }) => name).join(", ")}
    </AHText>
  );
}

function TodoBodyAndTags(props: { todo: TodoDetailBodyAndTags_todo$key }) {
  const todo = useFragment(
    graphql`
      fragment TodoDetailBodyAndTags_todo on Todo {
        body
        tags {
          id
          color
          title
        }
      }
    `,
    props.todo
  );

  return (
    <>
      {(todo.body ?? "").length > 0 ? (
        <AHText type="subtitle" spaceTop="xsmall">
          {todo.body}
        </AHText>
      ) : null}
      {todo.tags.length > 0 ? (
        <Box style={{ flexDirection: "row" }} spaceTop="small">
          {todo.tags.map((tag) => (
            <Box key={tag.id} spaceRight="small">
              <AHTag color={tag.color} title={tag.title} />
            </Box>
          ))}
        </Box>
      ) : null}
    </>
  );
}

// nsvariga användare
