import { Box } from "arkh";
import React, { useEffect, useState } from "react";

function getMasonryColumns<T>(images: T[]) {
  const array: T[][] = [];
  const numberOfColumns =
    images.length === 1
      ? 1
      : images.length === 2
      ? 2
      : images.length === 3
      ? 3
      : images.length === 4
      ? 3
      : Math.floor((images.length + 1) / 2);
  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    array[i % numberOfColumns] = [...(array[i % numberOfColumns] ?? []), image];
  }
  return array;
}

export function MasonryImageGrid(props: { images: { url: string }[] }) {
  const columns = getMasonryColumns(props.images);
  return (
    <Box
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
      }}
    >
      {columns.map((images, columnIdx) => {
        const key = images.map(({ url }) => url).join("");
        return (
          <React.Fragment key={columnIdx}>
            {columnIdx > 0 ? <Box spaceLeft="xsmall" key={columnIdx} /> : null}
            <Box
              key={`${key}-images`}
              style={{
                flex: 1,
                maxHeight: "100%",
                flexDirection: "column",
              }}
            >
              {images.map((img, rowIdx) =>
                img.url ? (
                  <React.Fragment key={img.url}>
                    {rowIdx > 0 ? (
                      <Box spaceBottom="xsmall" key={rowIdx} />
                    ) : null}
                    <Box key={`${img.url}-image`} style={{ flex: 1 }}>
                      <ProgressiveLoadingImage url={img.url} />
                    </Box>
                  </React.Fragment>
                ) : null
              )}
            </Box>
          </React.Fragment>
        );
      })}
    </Box>
  );
}

function ProgressiveLoadingImage(props: { url: string }) {
  return (
    <>
      <img
        src={props.url}
        style={{
          flex: 1,
          maxHeight: "100%",
          overflow: "hidden",
          objectPosition: "left top",
          objectFit: "scale-down",
        }}
      />
    </>
  );
}
