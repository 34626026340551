/**
 * @generated SignedSource<<6440108451c2c2593adcf2d249a86c5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TodoDetailBodyAndTags_todo$data = {
  readonly body: string | null;
  readonly tags: ReadonlyArray<{
    readonly color: string;
    readonly id: string;
    readonly title: string;
  }>;
  readonly " $fragmentType": "TodoDetailBodyAndTags_todo";
};
export type TodoDetailBodyAndTags_todo$key = {
  readonly " $data"?: TodoDetailBodyAndTags_todo$data;
  readonly " $fragmentSpreads": FragmentRefs<"TodoDetailBodyAndTags_todo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TodoDetailBodyAndTags_todo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TodoCategoryTag",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Todo",
  "abstractKey": null
};

(node as any).hash = "5d3c112a2e705ddc27d96bbc6268e059";

export default node;
