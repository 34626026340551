/**
 * @generated SignedSource<<52b29b70b116762a337ff9466f0f3105>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamScheduleFocusScreenColumn_teamSchedule$data = {
  readonly " $fragmentSpreads": FragmentRefs<"WidgetTeamScheduleContentList_teamSchedule" | "WidgetTeamScheduleContentSectionHeader_teamSchedule" | "WidgetTeamScheduleContentStatus_teamSchedule">;
  readonly " $fragmentType": "TeamScheduleFocusScreenColumn_teamSchedule";
};
export type TeamScheduleFocusScreenColumn_teamSchedule$key = {
  readonly " $data"?: TeamScheduleFocusScreenColumn_teamSchedule$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamScheduleFocusScreenColumn_teamSchedule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamScheduleFocusScreenColumn_teamSchedule",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WidgetTeamScheduleContentList_teamSchedule"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WidgetTeamScheduleContentSectionHeader_teamSchedule"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WidgetTeamScheduleContentStatus_teamSchedule"
    }
  ],
  "type": "TeamSchedule",
  "abstractKey": null
};

(node as any).hash = "f18175b02e3ed611b33301ad78d99e36";

export default node;
