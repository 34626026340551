import React, { useMemo } from "react";

import { InternetConnectionBar } from "./InternetConnectionBar";
import { NoAssignedDashboard } from "../NoAssignedDashboard";
import { useRegisterAliveDevice } from "../../OS/auth/registerAlive";
import { graphql, useLazyLoadQuery, useSubscription } from "react-relay";
import { Storecast } from "./Storecast";
import { StorecastScreenQuery } from "./__generated__/StorecastScreenQuery.graphql";
import { authService } from "../../OS/auth/authService";

import { StorecastFocus } from "../Focus";
import { StorecastScreenDashboardChangedSubscription } from "./__generated__/StorecastScreenDashboardChangedSubscription.graphql";

export const StorecastScreen = () => {
  const data = useLazyLoadQuery<StorecastScreenQuery>(
    graphql`
      query StorecastScreenQuery {
        storecast {
          screen {
            id
            name
            shouldAnalyze
            dashboard {
              id
              ...Storecast_storecast
            }
          }
        }
      }
    `,
    {},
    {
      fetchPolicy: "store-and-network",
      networkCacheConfig: {
        poll: 40000,
      },
    }
  );
  const screenId = data.storecast.screen.id;
  useRegisterAliveDevice(screenId);

  useSubscription<StorecastScreenDashboardChangedSubscription>(
    useMemo(
      () => ({
        onNext(response) {
          if (response?.storecastScreenChange === null) {
            authService.send("STORECAST_SCREEN_DEVICE_UNDEFINED");
          }
        },
        variables: { screenId },
        subscription: graphql`
          subscription StorecastScreenDashboardChangedSubscription(
            $screenId: ID!
          ) {
            storecastScreenChange(screenId: $screenId) {
              id
              name
              dashboard {
                id
                ...Storecast_storecast
              }
            }
          }
        `,
      }),
      [screenId]
    )
  );

  if (!data?.storecast.screen?.dashboard) {
    return (
      <NoAssignedDashboard
        screenName={data?.storecast.screen?.name ?? undefined}
      />
    );
  }

  return (
    <>
      <InternetConnectionBar />
      <Storecast
        storecast={data.storecast.screen.dashboard}
        screenName={data.storecast.screen.name}
      />
      <StorecastFocus
        key={data.storecast.screen.id}
        storecastId={data.storecast.screen.dashboard.id}
        shouldAnalyze={data.storecast.screen.shouldAnalyze}
      />
    </>
  );
};
