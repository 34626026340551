import * as Font from "expo-font";

import { FunctionComponent, useEffect, useState } from "react";

import { Caveat_700Bold } from "@expo-google-fonts/caveat";
import { Poppins_600SemiBold } from "@expo-google-fonts/poppins";
import { useFonts } from "expo-font";

export const fontMap = {
  "system-regular":
    "https://rsms.me/inter/font-files/Inter-Regular.woff2?v=4.0",
  "system-bolder":
    "https://rsms.me/inter/font-files/Inter-ExtraBold.woff2?v=4.0",
  "system-bold": "https://rsms.me/inter/font-files/Inter-SemiBold.woff2?v=4.0",
  "system-medium": "https://rsms.me/inter/font-files/Inter-Medium.woff2?v=4.0",
  "system-light": "https://rsms.me/inter/font-files/Inter-Light.woff2?v=4.0",
  "system-serif": Caveat_700Bold,
  "poppins-display": Poppins_600SemiBold,
};

export const FontLoader: FunctionComponent<any> = (props) => {
  const [fontLoaded, error] = useFonts(fontMap as any);
  const [timeout, setHasTimedOut] = useState(false);
  useEffect(() => {
    if (!timeout) {
      const clear = setTimeout(() => {
        setHasTimedOut(true);
      }, 3000);
      return () => {
        clearTimeout(clear);
      };
    }
  }, [timeout]);
  if (
    error ||
    timeout ||
    (fontLoaded && Object.keys(fontMap).every((font) => Font.isLoaded(font)))
  ) {
    return props.children ? props.children : null;
  }

  return null;
};
